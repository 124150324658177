import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import AxiosInstance from "../../../axiosInstance";

const SingleBlog = () => {
  const { id } = useParams();
  const [Data, setData] = useState("");
  useEffect(() => {
    AxiosInstance.get(`/blog/blog/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  return (
    <>
      <div
        className="relative w-full h-[400px] bg-cover bg-no-repeat bg-center text-white flex justify-center items-center"
        style={{ backgroundImage: `url(${Data?.blogImageUrl})` }}
      >
        <div className="absolute inset-0 bg-gray-900 opacity-50"></div>

        <Container>
          <div className="relative z-10">
            <p className="text-[2rem] md:text-[2.5rem] lg:text-[2.5rem] xl:text-[2.5rem] 2xl:text-[2.5rem] font-semibold capitalize text-center">
              {Data?.blog_title}
            </p>
          </div>
        </Container>
      </div>

      <Container>
        <div
          className="w-full h-full my-5"
          dangerouslySetInnerHTML={{ __html: Data?.blog_body }}
        ></div>
      </Container>
    </>
  );
};

export default SingleBlog;
