import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Searchbar = () => {
  const categoryData = useSelector((state) => state.allCategory.allCategory);
  const [searchInput, setSearchInput] = useState("");

  const filteredResults = categoryData.filter((category) => {
    const categoryMatch = category.category_name
      .toLowerCase()
      .includes(searchInput.toLowerCase());

    const subCategoryMatch = category.sub_categories.some((subCategory) =>
      subCategory.sub_category_name
        .toLowerCase()
        .includes(searchInput.toLowerCase())
    );

    return categoryMatch || subCategoryMatch;
  });

  return (
    <>
      <div className="relative flex items-center justify-center border px-2 rounded-lg">
        <div>
          <input
            type="text"
            className="px-3 text-gray-500 font-semibold py-2 w-[550px] md:w-[450px] xl:w-[550px] focus:outline-none"
            placeholder="Search Any Services"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)} // Update the search input state
          />
        </div>
        <div className="text-2xl bg-yellow-600 p-1 rounded-lg text-white cursor-pointer">
          <BiSearch />
        </div>
      </div>

      {/* Display the filtered results if the search input is not empty */}
      {searchInput && (
        <div className="absolute mt-2 w-[450px] bg-white h-auto border overflow-y-auto">
          {filteredResults.length > 0 ? (
            filteredResults.map((category) => (
              <div key={category.id} className="p-2 border-b">
                <div className="flex justify-start items-center gap-2">
                  <div className="w-10 h-10 border rounded-lg">
                    <img src={category.categoryBannerUrl} alt="" />
                  </div>
                  <div className="font-semibold text-lg">
                    {category.category_name}
                  </div>
                </div>

                {/* Check if there are subcategories that match the search input */}
                {category.sub_categories.length > 0 &&
                  category.sub_categories.map((subCategory) => {
                    if (
                      subCategory.sub_category_name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    ) {
                      return (
                        <div key={subCategory.sub_category_id}>
                          <Link
                            to={`/${subCategory.sub_category_name}`}
                            onClick={() => setSearchInput("")} // Clear search input on click
                          >
                            <div className="pl-4 mt-2 flex justify-start items-center gap-2">
                              <div className="w-8 h-8 border rounded-lg">
                                <img src={subCategory.subCatBannerUrl} alt="" />
                              </div>
                              <div className=" text-gray-500">
                                {subCategory.sub_category_name}
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            ))
          ) : (
            <div className="p-4 text-center text-gray-500">
              No matching results found
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Searchbar;
