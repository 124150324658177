import React, { useRef, useState } from "react";
import uploadImg from "../../../../../assets/admin/icon/upload.png";
import IcPng from "../../../../../assets/admin/icon/png.svg";
import IcJpg from "../../../../../assets/admin/icon/jpg.svg";
import Icppt from "../../../../../assets/admin/icon/ppt.svg";
import doc from "../../../../../assets/admin/icon/doc.svg";
import Icfile from "../../../../../assets/admin/icon/file.svg"; // Default file icon
import IcPDF from "../../../../../assets/admin/icon/pdf.svg";
import { TiDelete } from "react-icons/ti";
import AxiosInstance from "../../../../../axiosInstance";

const FileUploads = ({ handleViewData }) => {
  const [SelectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false); // State to track dragging
  const fileUpload = useRef(null);

  const handleUpload = () => {
    fileUpload.current.click();
  };

  const handleFileUploads = (e) => {
    const newFileArray = Array.from(e.target.files);
    setSelectedFiles([...SelectedFiles, ...newFileArray]);
  };

  // Prevent default behavior when dragging files over the drop zone
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true); // Set dragging state to true
  };

  // Handle file drop
  const handleDrop = (e) => {
    e.preventDefault();
    const newFileArray = Array.from(e.dataTransfer.files);
    setSelectedFiles([...SelectedFiles, ...newFileArray]);
    setIsDragging(false); // Reset dragging state after dropping files
  };

  // Reset dragging state if the user leaves the drop area without dropping
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  // Mapping file types to their respective icons
  const fileTypeImage = {
    "image/png": IcPng,
    "image/jpeg": IcJpg,
    "application/vnd.ms-powerpoint": Icppt,
    "application/msword": doc,
    "application/pdf": IcPDF,
  };
  const handleDelete = (file) => {
    setSelectedFiles(SelectedFiles.filter((f) => f.name !== file.name));
  };
  const handleClear = () => {
    setSelectedFiles([]);
  };
  const handleUploadData = async () => {
    const formData = new FormData();
    // Append files to FormData
    SelectedFiles.forEach((file) => {
      formData.append("files", file);
    });
    // Append additional data to FormData
    formData.append("created_by", "User");

    try {
      const response = await AxiosInstance.post("/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.data);
      handleViewData();
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-x-3  bg-slate-200/50 border rounded-2xl px-5 py-5">
      <div
        className={`border-4 rounded-xl border-blue-500 border-dotted ${
          isDragging ? "bg-black/10" : ""
        }`} // Apply background change based on dragging state
        onDragOver={handleDragOver} // Handle dragging over the drop zone
        onDragLeave={handleDragLeave} // Reset background on leaving drop zone
        onDrop={handleDrop} // Handle dropping files
      >
        <div className="py-2 flex justify-center items-center">
          <button onClick={handleUpload}>
            <div>
              <img className="h-[200px]" src={uploadImg} alt="Upload icon" />
              <p className="text-center font-semibold">
                Drag and Drop Files or Click
              </p>
            </div>
          </button>
          <input
            onChange={handleFileUploads}
            multiple
            ref={fileUpload}
            hidden
            type="file"
          />
        </div>
      </div>
      <div>
        <div className="overflow-y-scroll h-[240px] px-4">
          {SelectedFiles && SelectedFiles.length > 0 ? (
            <div>
              {SelectedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center bg-white mb-2 px-3 py-1 rounded-lg"
                >
                  <div className="flex gap-3 items-center">
                    <img
                      src={fileTypeImage[file.type] || Icfile}
                      alt=""
                      className="h-10 w-10"
                    />
                    <div>
                      <p>{file.name}</p>
                      <p>{(file.size / 1024).toFixed(2)} kb</p>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => handleDelete(file)}
                      className="text-red-500 text-2xl"
                    >
                      <TiDelete />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center ">
              <p className="text-center text-xl font-medium text-gray-400">
                No files selected
              </p>
            </div>
          )}
        </div>
        {SelectedFiles && SelectedFiles.length > 0 && (
          <div className="flex justify-end items-center gap-3 mt-3">
            <button onClick={handleClear}>Clear</button>
            <button
              onClick={handleUploadData}
              className="text-white text-xs bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] px-4 py-2 rounded-2xl"
            >
              Upload
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploads;
