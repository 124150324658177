import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../../axiosInstance";

const Cards = () => {
  const [CardData, setCardData] = useState("");
  useEffect(() => {
    AxiosInstance.get("/dashboard/table-counts").then((res) => {
      setCardData(res.data);
    });
  }, []);
  return (
    <div>
      <div className="grid grid-cols-4 gap-x-5">
        <div className=" w-full h-[120px] bg-indigo-700/50 rounded-2xl p-4 shadow-md flex justify-center items-center flex-col gap-2">
          <p className="font-bold text-3xl"> {CardData.service_count || 0}</p>
          <p className="text-sm font-semibold">Total Services</p>
        </div>
        <div className=" w-full h-[120px] bg-red-500/50 rounded-2xl p-4 shadow-md flex justify-center items-center flex-col gap-2">
          <p className="font-bold text-3xl"> {CardData.order_count || 0}</p>
          <p className="text-sm font-semibold">Total Orders</p>
        </div>
        <div className=" w-full h-[120px] bg-blue-500/50 rounded-2xl p-4 shadow-md flex justify-center items-center flex-col gap-2">
          <p className="font-bold text-3xl"> {CardData.category_count || 0}</p>
          <p className="text-sm font-semibold">Total Category</p>
        </div>
        <div className=" w-full h-[120px] bg-green-500/50 rounded-2xl p-4 shadow-md flex justify-center items-center flex-col gap-2">
          <p className="font-bold text-3xl"> {CardData.user_count || 0}</p>
          <p className="text-sm font-semibold">Total user</p>
        </div>
      </div>
    </div>
  );
};

export default Cards;
