import React from "react";

const Orders = () => {
  return (
    <div className="mt-5">
      <p>Orders</p>
    </div>
  );
};

export default Orders;
