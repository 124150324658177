import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import FileManagerPopup from "../../../../../components/admin/FileManager/FileManagerPopup";
import AxiosInstance from "../../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import {
  setCategoryBanner,
  setCategoryIcon,
} from "../../../../../context/admin/ImageSelectedSlice/ImageSelectedSlice";

const EditCategory = ({
  handleClose,
  handleViewData,
  handleCloseEdit,
  EditId,
}) => {
  const dispatch = useDispatch();
  const CategoryIcon = useSelector((state) => state.imageSelected.categoryIcon);
  const CategoryBanner = useSelector(
    (state) => state.imageSelected.categoryBanner
  );

  const [OpenFileManager, setOpenFileManager] = useState(false);
  const [OpenManagerforBanner, setOpenManagerforBanner] = useState(false);
  const [CateogoryIcon, setCateogoryIcon] = useState("");
  const [Categorybanner, setCategorybanner] = useState("");
  const [FormData, setFormData] = useState({
    category_name: "",
    category_icon: "",
    category_banner: "",
    status: 1,
  });
  const handleOpen = () => {
    setOpenFileManager(true);
  };
  const handleOpenManagerforBanner = () => {
    setOpenManagerforBanner(true);
  };

  const handleCloseManagerforBanner = () => {
    setOpenManagerforBanner(false);
  };
  const handleCloseFileManager = () => {
    setOpenFileManager(false);
  };
  useEffect(() => {
    setFormData({
      ...FormData,
      category_icon: CategoryIcon,
      category_banner: CategoryBanner,
    });
  }, [CategoryIcon, CategoryBanner]);
  useEffect(() => {
    AxiosInstance.get(`/file/get-file/${CategoryIcon}`)
      .then((res) => {
        setCateogoryIcon(res.data.fileUrl);
      })
      .catch((err) => {
        console.log(err);
      });
    AxiosInstance.get(`/file/get-file/${CategoryBanner}`)
      .then((res) => {
        setCategorybanner(res.data.fileUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [CategoryIcon, CategoryBanner]);
  const handleSubmit = () => {
    AxiosInstance.put(`/category/update-category/${EditId}`, FormData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Category has been edited.", {
            position: "bottom-center",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          handleCloseEdit();
          handleViewData();
          dispatch(setCategoryBanner(""));
          dispatch(setCategoryIcon(""));
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    AxiosInstance.get(`/category/category/${EditId}`)
      .then((res) => {
        const data = res.data;
        setFormData({
          category_name: data.category_name,
          category_icon: data.category_icon,
          category_banner: data.category_banner,
          status: data.status,
        });
        setCategorybanner(data.categoryBannerUrl);
        setCateogoryIcon(data.categoryIconUrl);
        dispatch(setCategoryBanner(data.category_banner));
        dispatch(setCategoryIcon(data.category_icon));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [EditId]);
  return (
    <div>
      <div className="absolute top-0 left-0 w-screen h-screen bg-black/20 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out  opacity-100">
        {OpenManagerforBanner && (
          <FileManagerPopup
            categoryIconNeeded={false}
            categoryBannerNeeded={true}
            subCategoryIconNeeded={false}
            subCategoryBannerNeeded={false}
            heroBannerNeeded={false}
            footerBannerNeeded={false}
            singleImageSelector={true}
            handleCloseManagerforBanner={handleCloseManagerforBanner}
          />
        )}
        {OpenFileManager && (
          <FileManagerPopup
            categoryIconNeeded={true}
            categoryBannerNeeded={false}
            subCategoryIconNeeded={false}
            subCategoryBannerNeeded={false}
            heroBannerNeeded={false}
            footerBannerNeeded={false}
            singleImageSelector={true}
            handleCloseFileManager={handleCloseFileManager}
          />
        )}
        <div className="bg-white relative p-6 rounded-md shadow-md w-[550px] h-auto transform transition-all duration-300 ease-out scale-95">
          <button
            onClick={handleCloseEdit}
            className="absolute -top-4 text-3xl -right-3 bg-white rounded-full text-rose-600 cursor-pointer"
          >
            <MdCancel />
          </button>
          <div className="w-full">
            <div>
              <p className="text-lg font-medium text-center text-black/70">
                Edit Category
              </p>
            </div>
            <div>
              <label htmlFor="to" className="text-sm text-black/70 font-medium">
                Category Name <span className="text-red-500">*</span>
              </label>
              <input
                id="category_name"
                type="text"
                onChange={(e) => {
                  setFormData({ ...FormData, category_name: e.target.value });
                }}
                value={FormData.category_name}
                placeholder="Category Name"
                className="border w-full px-4 py-1 rounded-md focus:outline-none"
              />
            </div>
            <div className="mt-4">
              <label htmlFor="to" className="text-sm text-black/70 font-medium">
                Category Icon<span className="text-red-500">*</span>
              </label>
              <div
                onClick={handleOpen}
                className="border w-full px-4 text-black/40 cursor-pointer font-medium py-1 rounded-md focus:outline-none"
              >
                {CateogoryIcon > 0
                  ? "Select Icon"
                  : "Change Icon or Upload Icon"}
              </div>
              {CateogoryIcon && (
                <div className="w-[100px] h-[100px] px-2 py-2 border-2 mt-2 rounded-lg">
                  <img
                    className="w-full h-full object-cover"
                    src={CateogoryIcon}
                    alt=""
                  />
                </div>
              )}
            </div>
            <div className="mt-4">
              <label htmlFor="to" className="text-sm text-black/70 font-medium">
                Category Banner<span className="text-red-500">*</span>
              </label>
              <div
                onClick={handleOpenManagerforBanner}
                className="border w-full px-4 text-black/40 cursor-pointer font-medium py-1 rounded-md focus:outline-none"
              >
                {Categorybanner > 0
                  ? "Select Banner"
                  : "Change Banner or Upload Banner"}
              </div>
              {Categorybanner && (
                <div className="w-[180px] h-[100px] px-2 py-2 border-2 mt-2 rounded-lg">
                  <img
                    className="w-full h-full object-cover"
                    src={Categorybanner}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end items-center gap-3 mt-5">
            <button onClick={handleCloseEdit} className=" text-xs  px-4 py-2 ">
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="text-white text-xs bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] px-4 py-2 rounded-2xl"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
