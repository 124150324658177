import React, { useEffect, useRef } from "react";

const Schedule = ({ handleChangeDateTime, FormData }) => {
  const dateInputRef = useRef(null);
  const timeInputRef = useRef(null);

  const handleDateClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  const handleTimeClick = () => {
    if (timeInputRef.current) {
      timeInputRef.current.showPicker();
    }
  };

  return (
    <div className="px-6 py-3">
      <div className="w-full py-2 px-4 text-center bg-white rounded-lg shadow-md border">
        <p>Schedule your service</p>
      </div>
      <div className="flex justify-between items-center gap-2 mt-3">
        <div
          className="flex items-center w-full gap-2 px-2 border rounded-lg bg-white cursor-pointer"
          onClick={handleDateClick}
        >
          <div className="flex-shrink-0">
            <p>Select Date</p>
          </div>
          <input
            ref={dateInputRef}
            className="w-full px-2 py-3 focus:outline-none"
            type="date"
            style={{ appearance: "none" }}
            onChange={(e) => handleChangeDateTime(e)}
            value={FormData.date}
            id="date"
          />
        </div>

        <div
          className="flex items-center w-full gap-2 px-2 border rounded-lg bg-white cursor-pointer"
          onClick={handleTimeClick}
        >
          <div className="flex-shrink-0">
            <p>Select Time</p>
          </div>
          <input
            ref={timeInputRef}
            className="w-full px-2 py-3 focus:outline-none"
            type="time"
            style={{ appearance: "none" }}
            onChange={(e) => handleChangeDateTime(e)}
            value={FormData.time}
            id="time"
          />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
