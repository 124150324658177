import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";

const EditDisplay = ({
  setDisplayData,
  setFormData,
  formData,
  handleViewData,
}) => {
  const [SubCategoryData, setSubCategoryData] = useState([]);

  // Fetch subcategory data on component mount
  useEffect(() => {
    AxiosInstance.get(`/subcategory/sub-categories-data`)
      .then((res) => {
        setSubCategoryData(res.data.subcategories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Handle title change
  const handleTitleChange = (index, e) => {
    const { value } = e.target;
    const updatedData = formData.map((item, i) =>
      i === index ? { ...item, display_title: value } : item
    );
    setFormData(updatedData);
  };

  // Handle service selection
  const handleServiceChange = (index, e) => {
    const { value } = e.target;

    if (!value) return;

    const selectedSubCategoryId = String(value);

    const updatedData = formData.map((item, i) => {
      if (i === index) {
        const existingServiceIndex = item.services.findIndex(
          (service) => service.sub_category_id === selectedSubCategoryId
        );

        let updatedServices;
        if (existingServiceIndex >= 0) {
          updatedServices = item.services.filter(
            (service) => service.sub_category_id !== selectedSubCategoryId
          );
        } else {
          updatedServices = [
            ...item.services,
            { sub_category_id: selectedSubCategoryId, status: 1 },
          ];
        }

        return { ...item, services: updatedServices };
      }
      return item;
    });

    setFormData(updatedData);
  };

  // Remove a selected service
  const removeService = (index, serviceId) => {
    const updatedData = formData.map((item, i) => {
      if (i === index) {
        const updatedServices = item.services.filter(
          (service) => service.sub_category_id !== serviceId
        );
        return { ...item, services: updatedServices };
      }
      return item;
    });
    setFormData(updatedData);
  };

  // Handle update for a specific section
  const handleUpdateSection = (id) => {
    // Find the section data by id
    const sectionData = formData.find((data) => data.id === id);

    if (sectionData) {
      // Exclude the id from the payload
      const { id, ...payload } = sectionData;

      AxiosInstance.put(`/display/update-service-displays/${id}`, payload)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Display has been updated.", {
              position: "bottom-center",
              autoClose: 1198,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            handleViewData();
          }
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(`No section found with id ${id}`);
    }
  };

  const handleDelete = (subCatId, displayId) => {
    AxiosInstance.delete(`/display/delete-service/${displayId}/${subCatId}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Display has been deleted.", {
            position: "bottom-center",
            autoClose: 1198,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          handleViewData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeleteAll = (displayId) => {
    AxiosInstance.delete(`/display/delete-service-display/${displayId}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Display has been deleted.", {
            position: "bottom-center",
            autoClose: 1198,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          handleViewData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="w-full p-5 bg-white border shadow-md rounded-xl mt-5">
      <h1 className="text-xl font-bold text-center">Manage Display Services</h1>
      <form className="grid grid-cols-3 gap-5 px-4 py-4 rounded-xl ">
        {formData.map((item, index) => (
          <div
            key={index}
            className="relative border my-5 px-4 rounded-xl pb-4 bg-slate-200/30 shadow-md "
          >
            <span
              onClick={() => handleDeleteAll(item.id)}
              className="absolute -right-2 -top-2 bg-red-500 text-white rounded-full shadow-md text-2xl cursor-pointer"
            >
              <RxCross2 />
            </span>
            <div className="my-5">
              <label
                htmlFor={`display_title_${index}`}
                className="text-sm font-medium"
              >
                Display Title <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name={`display_title_${index}`}
                value={item.display_title}
                onChange={(e) => handleTitleChange(index, e)}
                placeholder="Enter Display Title"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
              />
            </div>

            {/* Multiple Sub Category Dropdown */}
            <div className="my-7">
              <label
                htmlFor={`services_${index}`}
                className="text-sm font-medium"
              >
                Choose Multiple Sub Category{" "}
                <span className="text-red-500">*</span>
              </label>
              <select
                name={`services_${index}`}
                onChange={(e) => handleServiceChange(index, e)}
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              >
                <option value="">Select a Sub Category</option>
                {SubCategoryData.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.sub_category_name}
                  </option>
                ))}
              </select>

              {/* Display selected subcategories as tags */}
              <div className="mt-3 flex flex-wrap gap-2">
                {item.services.map((service, i) => {
                  const subCategory = SubCategoryData.find(
                    (sub) => sub.id === Number(service.sub_category_id)
                  );

                  return (
                    <div
                      key={i}
                      className="bg-gray-200 px-3 py-1 rounded-full flex items-center"
                    >
                      <span className="mr-2">
                        {subCategory
                          ? subCategory.sub_category_name
                          : "Unknown"}
                      </span>
                      <button
                        type="button"
                        onClick={() => {
                          removeService(index, service.sub_category_id);
                          handleDelete(service.sub_category_id, item.id);
                        }}
                        className="text-red-500 font-bold"
                      >
                        x
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Update Button for Each Section */}
            <div className="flex justify-end items-center mt-4">
              <button
                type="button"
                onClick={() => handleUpdateSection(item.id)}
                className="text-white text-sm bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] px-4 py-2 rounded-2xl"
              >
                Update Display Services
              </button>
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default EditDisplay;
