import React from "react";
import Container from "../../../utils/Container";

const PrivacyPolicy = () => {
  return (
    <Container>
      <div
        className="p-6 max-w-3xl mx-auto space-y-6 text-gray-800"
        dangerouslySetInnerHTML={{
          __html: `
          <h1 class="text-3xl font-bold text-gray-900 mb-4">Privacy Policy for Nirapod.xyz</h1>
          <p class="text-lg leading-relaxed">At Nirapod.xyz, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your information when you access or use our on-demand pest control services. By using our services, you consent to the data practices described in this policy.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">1. Information We Collect</h2>
          <p class="text-lg leading-relaxed">We may collect the following types of information:</p>
          <ul class="list-disc list-inside space-y-2 pl-4 text-lg">
            <li><strong>Personal Information:</strong> Name, contact details, address, and payment information necessary for service bookings and customer support.</li>
            <li><strong>Service Details:</strong> Information about your pest control needs, property type, and service history.</li>
            <li><strong>Technical Data:</strong> IP addresses, browser type, device information, and other technical details collected when you use our website or app.</li>
          </ul>

          <h2 class="text-2xl font-semibold mt-8 mb-4">2. How We Use Your Information</h2>
          <p class="text-lg leading-relaxed">We use your information for the following purposes:</p>
          <ul class="list-disc list-inside space-y-2 pl-4 text-lg">
            <li>To process and manage your bookings and payments.</li>
            <li>To communicate with you regarding service updates, offers, and support requests.</li>
            <li>To improve our services, website, and customer experience based on feedback and usage patterns.</li>
          </ul>

          <h2 class="text-2xl font-semibold mt-8 mb-4">3. Information Sharing and Disclosure</h2>
          <p class="text-lg leading-relaxed">We do not sell, rent, or share your personal information with third parties, except as follows:</p>
          <ul class="list-disc list-inside space-y-2 pl-4 text-lg">
            <li><strong>Service Providers:</strong> We may share information with trusted third-party providers to facilitate our services (e.g., payment processors, delivery partners).</li>
            <li><strong>Legal Compliance:</strong> We may disclose information if required by law or in response to legal requests, such as subpoenas or court orders.</li>
          </ul>

          <h2 class="text-2xl font-semibold mt-8 mb-4">4. Data Security</h2>
          <p class="text-lg leading-relaxed">We implement reasonable security measures to protect your data from unauthorized access, alteration, or disclosure. However, no security system is impenetrable, and we cannot guarantee the complete security of your information.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">5. Your Rights</h2>
          <p class="text-lg leading-relaxed">You have the right to access, update, or delete your personal information, subject to applicable law. If you wish to exercise any of these rights, please contact us at <a href="mailto:support@nirapod.xyz" class="text-blue-500 hover:underline">support@nirapod.xyz</a>.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">6. Changes to This Policy</h2>
          <p class="text-lg leading-relaxed">We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review the policy periodically to stay informed about our data practices.</p>

          <p class="text-lg leading-relaxed">For any questions or concerns regarding our Privacy Policy, please contact our support team at <a href="mailto:support@nirapod.xyz" class="text-blue-500 hover:underline">support@nirapod.xyz</a>.</p>
        `,
        }}
      />
    </Container>
  );
};

export default PrivacyPolicy;
