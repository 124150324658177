import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../../axiosInstance";
import TablePage from "../../../../../components/admin/table/pagination/TablePagination";
import TableContent from "../../../../../components/admin/table/content/TableContent";
import TableHeader from "../../../../../components/admin/table/table-header/TableHeader";

const TableOrder = ({ Toggle }) => {
  const [OrderData, setOrderData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const handleViewData = () => {
    AxiosInstance.get(`/order/orders?page=${page + 1}&pageSize=${rowsPerPage}`)
      .then((res) => {
        setOrderData(res.data.orders);
        setFilteredData(res.data.orders);
        setTotalItems(res.data.totalItems);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleViewData();
  }, [page, rowsPerPage]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = OrderData.filter((item) =>
      item.invoice_no.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleShowCancelled = () => {
    const cancelledOrders = OrderData.filter(
      (item) => item.payment_option === "Cancelled"
    );
    setFilteredData(cancelledOrders);
  };
  const handleShowComplete = () => {
    const cancelledOrders = OrderData.filter(
      (item) => item.payment_option === "Paid"
    );
    setFilteredData(cancelledOrders);
  };
  useEffect(() => {
    if (Toggle === 3) {
      const cancelledOrders = OrderData.filter(
        (item) => item.payment_option === "Cancelled"
      );
      setFilteredData(cancelledOrders);
    } else if (Toggle === 2) {
      const cancelledOrders = OrderData.filter(
        (item) => item.payment_option === "Completed"
      );
      setFilteredData(cancelledOrders);
    }
  }, [Toggle, filteredData]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to the first page
  };

  const tableHead = [
    "Sr. No.",
    "Invoice No",
    "Customer Name",
    "Phone Number",
    "Address",
    "Grand Total",
    "Status",
    "Payment Status",
    "Scheduled Date",
    "Updated Time",
    "Action",
  ];

  const tableData = [
    (item, index) => `<td>${index + 1}</td>`,
    (item) => `<td>${item.invoice_no}</td>`,
    (item) => `<td>${item.first_name} ${item.last_name}</td>`,
    (item) => `<td>${item.phone_number}</td>`,
    (item) => `<td>${item.house_no}, ${item.road}, ${item.city}</td>`,
    (item) => `<td>${item.subtotal}</td>`,
    (item) => `<td>${item.status}</td>`,
    (item) => `<td>${item.payment_option}</td>`,
    (item) => {
      const date = new Date(item.date);
      const formattedDate = date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return `<td>${formattedDate}</td>`;
    },
    (item) => {
      const date = new Date(item.updated_at);
      const formattedDate = date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return `<td>${formattedDate}</td>`;
    },
  ];

  return (
    <div className="w-full h-auto px-6 py-6 shadow-md rounded-lg">
      <TableHeader
        tableName="Orders"
        tableSubtitle="All order list with service details"
        buttonLink="/admin/services/add-service"
        buttonName="Add Service"
        placeHolder="Search a Invoice No"
        searchQuery={handleSearch}
        handleShowCancelled={handleShowCancelled}
      />
      {/* Button to filter Cancelled payment_option */}

      <TableContent
        tableData={tableData}
        filteredData={filteredData} // Updated filtered data based on button click
        tableHead={tableHead}
        editLink="/admin/order-management/order-details/"
        deleteApi="/order/order/"
        deletedText="Service has been deleted."
        handleViewData={handleViewData}
      />
      <TablePage
        count={totalItems} // Total items count for pagination
        page={page} // Current page
        rowsPerPage={rowsPerPage} // Rows per page
        handleChangePage={handleChangePage} // Function to handle page change
        handleChangeRowsPerPage={handleChangeRowsPerPage} // Function to change rows per page
      />
    </div>
  );
};

export default TableOrder;
