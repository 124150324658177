import React, { useState } from "react";
import fileIcon from "../../../../../assets/admin/icon/file.svg";
import FileActions from "../actions/FileActions";

const FileView = ({ FileData, handleViewData }) => {
  const [FileId, setFileId] = useState("");
  const isImage = (fileName) => {
    const ext = fileName.split(".").pop().toLowerCase();
    return ["png", "jpeg", "jpg", "webp", "svg"].includes(ext);
  };
  const shorterProductTitle = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };
  const handleAction = (id) => {
    setFileId(id);
  };

  return (
    <div className="w-full h-[490px] bg-slate-100 px-4 py-6 rounded-2xl  overflow-y-scroll mt-5">
      <div className="grid grid-cols-5 gap-4 ">
        {FileData && FileData.length > 0 ? (
          FileData.map((file, index) => {
            const fileName = shorterProductTitle(file.file_name, 35);

            return (
              <div
                key={index}
                className="flex flex-col items-center text-center"
              >
                {isImage(file.file_name) ? (
                  <div className="flex flex-col border relative w-[250px] h-[250px] items-center justify-center px-2 py-2 bg-white rounded-lg break-words overflow-hidden">
                    <span
                      onClick={() => handleAction(file.id)}
                      className="absolute top-0 right-0"
                    >
                      <FileActions
                        handleViewData={handleViewData}
                        FileId={FileId}
                      />
                    </span>
                    <img
                      src={file.fileUrl}
                      alt={file.file_name}
                      className="w-32 h-32 object-contain mb-2"
                    />
                    <p className="text-sm font-medium break-words">
                      {fileName}
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col border relative w-[250px] h-[250px] items-center justify-center px-2 py-2 bg-white rounded-lg break-words">
                    <span
                      onClick={() => handleAction(file.id)}
                      className="absolute top-0 right-0"
                    >
                      <FileActions
                        handleViewData={handleViewData}
                        FileId={FileId}
                      />
                    </span>
                    <img
                      src={fileIcon}
                      alt="File Icon"
                      className="w-32 h-32 object-cover mb-2"
                    />
                    <p className="text-sm font-medium break-words">
                      {fileName}
                    </p>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="text-center text-gray-500 flex justify-center items-center">
            No files available
          </div>
        )}
      </div>
    </div>
  );
};

export default FileView;
