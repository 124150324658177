import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";

const initialState = {
  token: "",
  userInfo: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setAcessToken: (state, action) => {
      state.token = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setAcessToken, setUserInfo } = loginSlice.actions;

export default loginSlice.reducer;
