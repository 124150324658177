import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCategory: [],
};

const allCategorySlice = createSlice({
  name: "allCategory",
  initialState,
  reducers: {
    setAllCategory: (state, action) => {
      state.allCategory = action.payload;
    },
  },
});

export const { setAllCategory } = allCategorySlice.actions;

export default allCategorySlice.reducer;
