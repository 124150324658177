import { Bounce, toast } from "react-toastify";
import AxiosInstance from "../../axiosInstance";
import { setBlogImageSelected } from "../../context/admin/ImageSelectedSlice/ImageSelectedSlice";

export const getOrderbyId = (id, setData, setPaymentOptions) => {
  AxiosInstance.get(`/order/orders/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const changePaymentStatus = (
  id,
  PaymentOptions,
  orderId,
  setData,
  setPaymentOptions
) => {
  AxiosInstance.put(`/order/order-payment/${id}`, PaymentOptions)
    .then((res) => {
      if (res.status === 200) {
        toast.success("Payment status has been updated", {
          position: "bottom-center",
          autoClose: 1200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        getOrderbyId(orderId, setData, setPaymentOptions);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getImagebyId = (selectedImage, setSelectedImage) => {
  AxiosInstance.get(`/file/get-file/${selectedImage}`)
    .then((res) => {
      setSelectedImage(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addBlog = (FormData, navigate) => {
  AxiosInstance.post("/blog/add-blog", FormData)
    .then((res) => {
      if (res.status === 201) {
        toast.success("Blog has been added", {
          position: "bottom-center",
          autoClose: 1200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        navigate("/admin/content/blog");
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getBlog = (setData) => {
  AxiosInstance.get(`/blog/blogs`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getBlogbyId = (id, setData, setEditorContent, dispatch) => {
  AxiosInstance.get(`/blog/blog/${id}`)
    .then((res) => {
      const data = res.data;
      setData({
        ...data,
        blog_title: data.blog_title,
        blog_image_id: data.blog_image_id,
        blog_body: data.blog_body,
      });
      setEditorContent(data.blog_body);
      dispatch(setBlogImageSelected(data.blog_image_id));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateBlog = (id, FormData) => {
  AxiosInstance.put(`/blog/add-blog/${id}`, FormData)
    .then((res) => {
      if (res.status === 201) {
        toast.success("Blog has been added", {
          position: "bottom-center",
          autoClose: 1200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
