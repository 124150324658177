import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phone_number: "",
};
const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    setPhoneNumberForOTP: (state, action) => {
      state.phone_number = action.payload;
    },
  },
});

export const { setPhoneNumberForOTP } = otpSlice.actions;

export default otpSlice.reducer;
