import AuthLayout from "../../layout/login/AuthLayout";
import AdminLogin from "../../view/admin/login/AdminLogin";

const authRouter = {
  path: "/auth",
  element: <AuthLayout />,
  children: [
    {
      path: "/auth/login",
      element: <AdminLogin />,
    },
  ],
};

export default authRouter;
