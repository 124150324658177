import React from "react";
import Cards from "./components/cards/Cards";
import Orders from "./components/orders/Orders";

const Dashboard = () => {
  return (
    <div>
      <Cards />
      <Orders />
    </div>
  );
};

export default Dashboard;
