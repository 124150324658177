import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";

const AddDisplay = ({ handleViewData }) => {
  const [SubCategoryData, setSubCategoryData] = useState([]);
  const [formData, setFormData] = useState([
    {
      display_title: "",
      status: 1,
      created_by: "user123",
      services: [],
    },
  ]);

  // Fetch subcategory data on component mount
  useEffect(() => {
    AxiosInstance.get(`/subcategory/sub-categories-data`)
      .then((res) => {
        setSubCategoryData(res.data.subcategories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Handle title change
  const handleTitleChange = (index, e) => {
    const { value } = e.target;
    const updatedData = formData.map((item, i) =>
      i === index ? { ...item, display_title: value } : item
    );
    setFormData(updatedData);
  };

  // Handle service selection
  const handleServiceChange = (index, e) => {
    const { value } = e.target;

    if (!value) return;

    const selectedSubCategoryId = String(value);

    const updatedData = formData.map((item, i) => {
      if (i === index) {
        const existingServiceIndex = item.services.findIndex(
          (service) => service.sub_category_id === selectedSubCategoryId
        );

        let updatedServices;
        if (existingServiceIndex >= 0) {
          updatedServices = item.services.filter(
            (service) => service.sub_category_id !== selectedSubCategoryId
          );
        } else {
          updatedServices = [
            ...item.services,
            { sub_category_id: selectedSubCategoryId, status: 1 },
          ];
        }

        return { ...item, services: updatedServices };
      }
      return item;
    });

    setFormData(updatedData);
  };

  // Remove a selected service
  const removeService = (index, serviceId) => {
    const updatedData = formData.map((item, i) => {
      if (i === index) {
        const updatedServices = item.services.filter(
          (service) => service.sub_category_id !== serviceId
        );
        return { ...item, services: updatedServices };
      }
      return item;
    });
    setFormData(updatedData);
  };

  // Add new display section
  const addNewFormSection = () => {
    setFormData([
      ...formData,
      {
        display_title: "",
        status: 1,
        created_by: "user123",
        services: [],
      },
    ]);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    AxiosInstance.post("/display/add-service-displays", formData)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Display has been added.", {
            position: "bottom-center",
            autoClose: 1198,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          setFormData([
            {
              display_title: "",
              status: 1,
              created_by: "user123",
              services: [],
            },
          ]);
          handleViewData();
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="w-full">
      <form
        className="w-full border px-4 py-4 rounded-xl shadow-lg"
        onSubmit={handleSubmit}
      >
        <h1 className="text-xl font-bold text-center">Add Display Services</h1>

        {formData.map((item, index) => (
          <div key={index} className="border-b py-4">
            {/* Display Title */}
            <div className="my-7">
              <label
                htmlFor={`display_title_${index}`}
                className="text-sm font-medium"
              >
                Display Title <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name={`display_title_${index}`}
                value={item.display_title}
                onChange={(e) => handleTitleChange(index, e)}
                placeholder="Enter Display Title"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
              />
            </div>

            {/* Multiple Sub Category Dropdown */}
            <div className="my-7">
              <label
                htmlFor={`services_${index}`}
                className="text-sm font-medium"
              >
                Choose Multiple Sub Category{" "}
                <span className="text-red-500">*</span>
              </label>
              <select
                name={`services_${index}`}
                onChange={(e) => handleServiceChange(index, e)}
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              >
                <option value="">Select a Sub Category</option>
                {SubCategoryData.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.sub_category_name}
                  </option>
                ))}
              </select>

              {/* Display selected subcategories as tags */}
              <div className="mt-3 flex flex-wrap gap-2">
                {item.services.map((service, i) => {
                  const subCategory = SubCategoryData.find(
                    (sub) => sub.id === Number(service.sub_category_id)
                  );

                  return (
                    <div
                      key={i}
                      className="bg-gray-200 px-3 py-1 rounded-full flex items-center"
                    >
                      <span className="mr-2">
                        {subCategory
                          ? subCategory.sub_category_name
                          : "Unknown"}
                      </span>
                      <button
                        type="button"
                        onClick={() =>
                          removeService(index, service.sub_category_id)
                        }
                        className="text-red-500 font-bold"
                      >
                        x
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

        {/* Add New Display Section */}
        <button
          type="button"
          onClick={addNewFormSection}
          className="text-sm bg-gray-300 px-4 py-2 mt-3 rounded-2xl mb-4"
        >
          Add New Display Section
        </button>

        {/* Submit & Clear Buttons */}
        <div className="flex justify-end items-center gap-3 mt-6">
          <button
            type="reset"
            className="text-black/80 text-sm px-4 py-2 rounded-2xl"
          >
            Clear
          </button>
          <button
            type="submit"
            className="text-white text-sm bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] px-4 py-2 rounded-2xl"
          >
            Add Display Services
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddDisplay;
