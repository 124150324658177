import React, { useEffect, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import UserAddress from "../../../../../components/frontend/address/UserAddress";
import {
  deleteAddress,
  getAddress,
} from "../../../../../api/frontend/frontendAPI";
import { MdOutlineEdit } from "react-icons/md";
import { TiDeleteOutline } from "react-icons/ti";
import { Tooltip } from "@mui/material";
import AddAddress from "../../../../../components/frontend/address/add-address/AddAddress";
import { FaHouseUser } from "react-icons/fa";

const Address = ({ FormData, handleSelectAddress }) => {
  const [ShowAddAddress, setShowAddAddress] = useState(false);
  const [EditAddress, setEditAddress] = useState(false);
  const [EditId, setEditId] = useState("");
  const [address, setAddress] = useState([]);
  const handleShowAddress = () => {
    setShowAddAddress(true);
  };
  const handleClose = () => {
    setShowAddAddress(false);
    setEditAddress(false);
  };
  const handleEditAddress = (id) => {
    setEditAddress(true);
    setEditId(id);
  };
  useEffect(() => {
    getAddress(setAddress);
  }, []);
  const handleDeleteAddress = (id) => {
    deleteAddress(id, setAddress);
  };
  return (
    <>
      {EditAddress && (
        <UserAddress
          EditId={EditId}
          setAddress={setAddress}
          setEditAddress={setEditAddress}
          EditAddress={EditAddress}
          handleClose={handleClose}
        />
      )}

      <div className="px-6 py-3 mt-10">
        <div className="w-full py-2 px-2 flex justify-between items-center text-center bg-white rounded-lg shadow-md border">
          <p>Select Your Address</p>
          <button
            onClick={handleShowAddress}
            className="flex justify-center text-sm items-center px-2 py-2 gap-2 bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] text-white rounded-full"
          >
            <FaPlusCircle />
          </button>
        </div>
        {ShowAddAddress === false && (
          <div>
            <AddAddress
              setShowAddAddress={setShowAddAddress}
              setAddress={setAddress}
            />
          </div>
        )}
        {ShowAddAddress && (
          <div className="mt-3 grid grid-flow-row grid-cols-1 gap-4 w-full border rounded-lg px-4 py-5">
            {address && address.length > 0 ? (
              <div
                onClick={() => handleSelectAddress(address[0].id)}
                className={`w-full  bg-white rounded-lg shadow-md border justify-start gap-2 px-5 py-10 items-center flex cursor-pointer ${
                  FormData.address_id === address[0].id &&
                  "bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] text-white"
                }`}
                key={address[0].id}
              >
                <div className="text-3xl bg-yellow-700 text-white px-2 py-2 rounded-lg">
                  <FaHouseUser />
                </div>
                <div>
                  <p className="text-lg text-gray-500">Tab to select address</p>
                  <p className="text-xl">
                    {address[0].house_no}, {address[0].road}, {address[0].city}
                  </p>
                </div>
                {/* <div className="flex gap-2 items-center">
                  <Tooltip title="Edit">
                    <button onClick={() => handleEditAddress(address[0].id)}>
                      <MdOutlineEdit />
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <button onClick={() => handleDeleteAddress(address[0].id)}>
                      <TiDeleteOutline />
                    </button>
                  </Tooltip>
                </div> */}
              </div>
            ) : (
              <div className="mt-3 flex justify-center w-full rounded-lg px-4 py-5 items-center">
                <p>No addresses found.</p>{" "}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Address;
