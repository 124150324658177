import { Bounce, toast } from "react-toastify";
import AxiosInstance from "../../axiosInstance";
import { setAllCategory } from "../../context/frontend/allCategorySlice/allCategorySlice";

export const getAddress = (setAddress) => {
  AxiosInstance.get("/address/address")
    .then((res) => {
      setAddress(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addOrder = (FormData) => {
  AxiosInstance.post("/order/add-order", FormData)
    .then((res) => {
      console.log(res.data);
      if (res.status === 201) {
        toast.success("Order has been added", {
          position: "bottom-center",
          autoClose: 1200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        sessionStorage.removeItem("selectedServices");
        window.location.href = "/";
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAddressbyId = (EditId, setFormData, userId) => {
  AxiosInstance.get(`/address/address/${EditId}/${userId}`)
    .then((res) => {
      const data = res.data;

      setFormData((prevFormData) => ({
        ...prevFormData,
        house_no: data.house_no,
        road: data.road,
        city: data.city,
        status: data.status,
      }));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateAddress = (EditId, FormData, handleClose, setAddress) => {
  AxiosInstance.put(`/address/update-address/${EditId}`, FormData)
    .then((res) => {
      if (res.status === 200) {
        toast.success("Address has been updated", {
          position: "bottom-center",
          autoClose: 1200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        getAddress(setAddress);
        handleClose();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteAddress = (id, setAddress) => {
  AxiosInstance.delete(`/address/delete-address/${id}`)
    .then((res) => {
      if (res.status === 200) {
        toast.success("Address has been deleted", {
          position: "bottom-center",
          autoClose: 1200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        getAddress(setAddress);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const allCategory = (dispatch) => {
  AxiosInstance.get("/category/all-category")
    .then((res) => {
      dispatch(setAllCategory(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};
