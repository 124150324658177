import React, { useState, useEffect } from "react";
import { getBlog } from "../../../api/backend/backendAPI";
import { useNavigate } from "react-router";
import AxiosInstance from "../../../axiosInstance";
import { Bounce, toast } from "react-toastify";

const Blog = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  // Corrected to use useEffect for data fetching
  useEffect(() => {
    getBlog(setData); // Assuming getBlog sets the data correctly
  }, []);
  const shorterProductTitile = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const handleAction = (id) => {
    navigate(`/admin/content/edit-blog/${id}`);
  };
  const handleDelete = (id) => {
    AxiosInstance.delete(`/blog/delete-blog/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Blog has been deleted", {
            position: "bottom-center",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          getBlog(setData); // Assuming getBlog sets the data correctly
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="grid grid-flow-row gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {data &&
        data.map((item, index) => (
          <div key={index} className="w-[300px] h-auto">
            <div className=" border overflow-hidden  flex justify-start items-center flex-col rounded-lg cursor-pointer shadow-md">
              <div className="w-full h-[150px]  rounded-lg">
                <img
                  className="w-full h-full aspect-square object-cover rounded-lg"
                  src={item.blogImageUrl}
                  alt=""
                />
              </div>
              <div className="mt-2">
                <p className="font-bold px-4 py-2">{item.blog_title}</p>
              </div>
              <div className="w-full flex justify-center items-center">
                <button
                  onClick={() => handleAction(item.id)}
                  className="bg-amber-500 w-full  hover:bg-amber-600 duration-300 text-white"
                >
                  Edit Blog
                </button>
                <button
                  onClick={() => handleDelete(item.id)}
                  className="bg-red-500 w-full  hover:bg-amber-600 duration-300 text-white"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Blog;
