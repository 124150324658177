import React, { useEffect } from "react";
import { MdCancel } from "react-icons/md";
import AddAddress from "./add-address/AddAddress";
import EditAddresses from "./edit-address/EditAddress";

const UserAddress = ({
  handleClose,
  ShowAddAddress,
  setAddress,
  EditAddress,
  EditId,
  setEditAddress,
}) => {
  useEffect(() => {
    const disableScroll = () => {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
      document.body.style.touchAction = "none"; // Disable touch scrolling for mobile
    };

    const enableScroll = () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
      document.body.style.touchAction = ""; // Restore touch action
    };

    disableScroll();

    return () => enableScroll();
  }, []);

  return (
    <div className="absolute top-0 left-0 w-screen h-screen  bg-black/20 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out  opacity-100">
      <div className="bg-white relative p-6 rounded-md shadow-md w-[550px] h-[350px] flex justify-center items-center transform transition-all duration-300 ease-out scale-95">
        <button
          onClick={handleClose}
          className="absolute -top-4 text-3xl -right-3 bg-white rounded-full text-rose-600 cursor-pointer"
        >
          <MdCancel />
        </button>
        <div className="w-full">
          {EditAddress && (
            <EditAddresses
              EditId={EditId}
              setAddress={setAddress}
              setEditAddress={setEditAddress}
              EditAddress={EditAddress}
              handleClose={handleClose}
            />
          )}
          {ShowAddAddress && (
            <AddAddress handleClose={handleClose} setAddress={setAddress} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserAddress;
