import React, { useEffect, useState } from "react";
import FileManagerPopup from "../../../../../components/admin/FileManager/FileManagerPopup";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { setServiceImage } from "../../../../../context/admin/ImageSelectedSlice/ImageSelectedSlice";

const AddService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ServiceImage = useSelector((state) => state.imageSelected.serviceImage);

  const [ServiceImageUrl, setServiceImageUrl] = useState("");
  const [ShowFileManagerForIcon, setShowFileManagerForIcon] = useState(false);
  const [CategoryData, setCategoryData] = useState([]);
  const [SubCategoryData, SubsetCategoryData] = useState([]);
  const [RelatatedService, setRelatatedService] = useState(false);
  const [relatedServices, setRelatedServices] = useState([
    { related_service_name: "", related_service_price: "" },
  ]);

  const handleAddRelatedService = () => {
    setRelatedServices([
      ...relatedServices,
      { related_service_name: "", related_service_price: "" },
    ]);
  };

  const handleRemoveRelatedService = (index) => {
    setRelatedServices(relatedServices.filter((_, i) => i !== index));
  };

  const handleRelatedServiceChange = (index, field, value) => {
    setRelatedServices((prevServices) =>
      prevServices.map((service, i) =>
        i === index ? { ...service, [field]: value } : service
      )
    );
  };

  const [FormData, setFormData] = useState({
    service_name: "",
    service_image: "",
    highlighted_one: "",
    highlighted_two: "",
    price: "",
    discounted_price: "",
    unit: "",
    status: 1,
    main_category_id: "",
    sub_category_id: "",
    created_by: "user",
    related_service_discount: "",
    related_services: [],
  });
  const handleUpdate = () => {
    const updatedFormData = { ...FormData, related_services: relatedServices };
    setFormData(updatedFormData);
  };
  const handleOpenRelatedService = () => {
    setRelatatedService(!RelatatedService);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleShowFileManagerForIcon = () => {
    setShowFileManagerForIcon(true);
  };
  const handleCloseFileManagerForIcon = () => {
    setShowFileManagerForIcon(false);
  };

  useEffect(() => {
    AxiosInstance.get(`/file/get-file/${ServiceImage}`)
      .then((res) => {
        setServiceImageUrl(res.data.fileUrl);
      })
      .catch((err) => {
        console.log(err);
      });
    setFormData((prevFormData) => ({
      ...prevFormData,
      service_image: ServiceImage,
    }));
  }, [ServiceImage]);

  const handleSubmit = () => {
    if (
      FormData.service_name === "" ||
      FormData.service_image === "" ||
      FormData.price === "" ||
      FormData.unit === "" ||
      FormData.discounted_price === "" ||
      FormData.main_category_id === "" ||
      FormData.sub_category_id === ""
    ) {
      toast.error("Please fill up all the fields.", {
        position: "bottom-center",
        autoClose: 1200,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      AxiosInstance.post("/service/add-service", FormData)
        .then((res) => {
          if (res.status === 201) {
            toast.success("Service has been added.", {
              position: "bottom-center",
              autoClose: 1200,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            navigate("/admin/services/services");
            setFormData({
              sub_category_name: "",
              sub_cat_icon: "",
              sub_cat_banner: "",
              highlighted_one: "",
              highlighted_two: "",
              details: "",
              main_category_id: "",
              status: 1,
              created_by: "user",
            });

            dispatch(setServiceImage(""));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    AxiosInstance.get("/category/category")
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    AxiosInstance.get(
      `/subcategory/sub-categories/${FormData.main_category_id}`
    )
      .then((res) => {
        SubsetCategoryData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [FormData.main_category_id]);
  const handleSelectMainCategory = (id) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      main_category_id: id,
    }));
  };
  const handleSelectSubCategory = (id) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sub_category_id: id,
    }));
  };
  console.log(FormData);
  return (
    <>
      {ShowFileManagerForIcon && (
        <FileManagerPopup
          seviceImage={true}
          subCategoryIconNeeded={false}
          subCategoryBannerNeeded={false}
          handleCloseFileManagerForIcon={handleCloseFileManagerForIcon}
        />
      )}

      <div className="p-4 flex justify-start items-center flex-col ">
        <div className="w-1/2 border px-4 py-4 rounded-xl shadow-lg">
          <h1 className="text-xl font-bold text-center">Add A Service</h1>
          <div className=" ">
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Service Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="service_name"
                onChange={handleChange}
                value={FormData.service_name}
                placeholder="Enter Sub Category Name"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
              />
            </div>

            <div className="my-7">
              <label htmlFor="sub_cat_icon" className="text-sm font-medium">
                Service Image <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                onChange={handleChange}
                onClick={handleShowFileManagerForIcon}
                value={FormData.service_image}
                autoComplete="off"
                name="sub_cat_icon"
                placeholder="Enter Sub Category Icon"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
              />
              {ServiceImageUrl && (
                <div className="w-[60px] h-[60px] mt-3 border px-1 py-1 rounded-lg">
                  <img
                    className="w-full h-full object-contain"
                    src={ServiceImageUrl}
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="my-7">
              <label htmlFor="highlighted_one" className="text-sm font-medium">
                Highlighed TagLine One
              </label>
              <input
                type="text"
                onChange={handleChange}
                value={FormData.highlighted_one}
                name="highlighted_one"
                placeholder="Highlighed TagLine"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              />
            </div>

            <div className="my-7">
              <label htmlFor="highlighted_two" className="text-sm font-medium">
                Highlighed TagLine Two
              </label>
              <input
                type="text"
                onChange={handleChange}
                value={FormData.highlighted_two}
                name="highlighted_two"
                placeholder="Highlighed TagLine Two"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              />
            </div>
            <div className="my-7">
              <label htmlFor="price" className="text-sm font-medium">
                Regular Price <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="price"
                onChange={handleChange}
                value={FormData.price}
                placeholder="Enter Sub Category Name"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
              />
            </div>
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Discounted Price <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="discounted_price"
                onChange={handleChange}
                value={FormData.discounted_price}
                placeholder="Enter Sub Category Name"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
              />
            </div>
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Unit <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="unit"
                onChange={handleChange}
                value={FormData.unit}
                placeholder="Enter Sub Category Name"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
              />
            </div>
            <div className="my-7">
              <label htmlFor="highlighted_two" className="text-sm font-medium">
                Choose Main Category <span className="text-red-500">*</span>
              </label>
              <select
                onChange={(e) => handleSelectMainCategory(e.target.value)}
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              >
                <option value="" disabled selected>
                  Choose Main Category
                </option>
                {CategoryData &&
                  CategoryData.length > 0 &&
                  CategoryData.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.category_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="my-7">
              <label htmlFor="highlighted_two" className="text-sm font-medium">
                Choose Sub Category <span className="text-red-500">*</span>
              </label>
              <select
                onChange={(e) => handleSelectSubCategory(e.target.value)}
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              >
                {SubCategoryData && SubCategoryData.length > 0 ? (
                  <>
                    {SubCategoryData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.sub_category_name}
                      </option>
                    ))}
                  </>
                ) : (
                  <option>Please select main category first</option>
                )}
              </select>
            </div>
          </div>
          <div className="my-7">
            <label htmlFor="sub_category_name" className="text-sm font-medium">
              Related Services Discount
            </label>
            <input
              type="number"
              name="related_service_discount"
              onChange={handleChange}
              value={FormData.related_service_discount}
              placeholder="Enter Discount Amount"
              className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              required
            />
          </div>
          {RelatatedService === false && (
            <div className="flex justify-end items-center gap-2">
              <p>Do you want to add related services?</p>
              <button
                onClick={handleOpenRelatedService}
                className="text-white  bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] text-lg px-4 rounded-2xl"
              >
                +
              </button>
            </div>
          )}
          {RelatatedService && (
            <>
              <div className="text-center text-lg font-bold">
                Related Services
              </div>
              <hr />
            </>
          )}
          {RelatatedService && (
            <>
              {relatedServices.map((service, index) => (
                <div className="flex items-center gap-1 mt-2" key={index}>
                  <input
                    type="text"
                    value={service.related_service_name}
                    onChange={(e) =>
                      handleRelatedServiceChange(
                        index,
                        "related_service_name",
                        e.target.value
                      )
                    }
                    placeholder="Enter related service name"
                    className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                  />
                  <input
                    type="text"
                    value={service.related_service_price}
                    onChange={(e) =>
                      handleRelatedServiceChange(
                        index,
                        "related_service_price",
                        e.target.value
                      )
                    }
                    placeholder="Enter related service price"
                    className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                  />
                  {FormData.related_service_discount && (
                    <input
                      disabled
                      type="text"
                      value={
                        service.related_service_price -
                        FormData.related_service_discount
                      }
                      placeholder="Discounted price"
                      className="border px-4 py-2 rounded-2xl w-2/4 focus:outline-none"
                    />
                  )}
                  <button
                    onClick={() => handleRemoveRelatedService(index)}
                    className="text-red-500 text-lg px-4 rounded-2xl"
                  >
                    X
                  </button>
                </div>
              ))}
            </>
          )}
          {RelatatedService && (
            <div className="flex justify-center items-center gap-2 mt-4">
              <button
                onClick={handleUpdate}
                className="text-white  bg-green-700 text-sm px-4 rounded-2xl"
              >
                Add Related Services
              </button>
              <button
                onClick={handleAddRelatedService}
                className="text-white  bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] text-sm px-4 rounded-2xl"
              >
                Add more related services
              </button>
            </div>
          )}
          <div className="flex justify-end items-center gap-3 mt-[3.5rem]">
            <button
              onClick={handleSubmit}
              className="text-white text-sm bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] px-4 py-2 rounded-2xl"
            >
              Add Service
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddService;
