import React, { useEffect, useState } from "react";
import { BiData, BiMinus, BiPlus } from "react-icons/bi";
import { FaArrowRightLong } from "react-icons/fa6";

const ServiceQuantity = ({ Data, relatedId }) => {
  const [serviceQuantities, setServiceQuantities] = useState({});
  const [relatedQuantities, setRelatedQuantities] = useState({});
  const [formData, setFormData] = useState([]);
  const [showRelatedService, setShowRelatedService] = useState(false);
  const [RelatedServiceData, setRelatedServiceData] = useState("");

  const initializeQuantities = () => {
    const initialQuantities = {};
    Data.services.forEach((service) => {
      initialQuantities[service.id] = 0;
    });

    if (RelatedServiceData.related_service) {
      RelatedServiceData.related_service.forEach((item) => {
        initialQuantities[item.id] = 0; // Reusing the same object for both services
      });
    }
    setServiceQuantities(initialQuantities);
    setRelatedQuantities(initialQuantities);
  };

  const handleShowRelatedService = (id) => {
    const service = Data.services.find((service) => service.id === id);
    setRelatedServiceData(service);
    setShowRelatedService(true);
  };

  useEffect(() => {
    if (relatedId) {
      handleShowRelatedService(relatedId);
    } else {
      setShowRelatedService(false);
    }
  }, [relatedId]);

  useEffect(() => {
    initializeQuantities();
  }, [Data, RelatedServiceData]);

  const handleCloseRelatedService = () => {
    setShowRelatedService(false);
  };

  const updateFormData = (serviceId, quantity, isRelatedService = false) => {
    const service = isRelatedService
      ? RelatedServiceData.related_service.find((item) => item.id === serviceId)
      : Data.services.find((service) => service.id === serviceId);

    const updatedServiceData = {
      service_id: service.service_id || service.id,
      service_name: service.related_service_name || service.service_name,
      quantity: quantity,
      price:
        service.related_service_price ||
        service.price - service.discounted_price,
      sub_cat_id: service.sub_cat_id || null,
      related_service_discount:
        RelatedServiceData.related_service_discount || 0,
    };

    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      const existingServiceIndex = updatedFormData.findIndex(
        (item) => item.service_name === updatedServiceData.service_name
      );

      if (existingServiceIndex !== -1) {
        updatedFormData[existingServiceIndex] = updatedServiceData;
      } else {
        updatedFormData.push(updatedServiceData);
      }

      sessionStorage.setItem(
        "selectedServices",
        JSON.stringify(updatedFormData)
      );
      return updatedFormData;
    });
  };

  const handleIncrement = (serviceId, isRelatedService = false) => {
    if (isRelatedService) {
      setRelatedQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        newQuantities[serviceId] = (newQuantities[serviceId] || 0) + 1;

        updateFormData(serviceId, newQuantities[serviceId], true);
        return newQuantities;
      });
    } else {
      setServiceQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        newQuantities[serviceId] = (newQuantities[serviceId] || 0) + 1;

        updateFormData(serviceId, newQuantities[serviceId]);
        return newQuantities;
      });
    }
  };

  const handleDecrement = (serviceId, isRelatedService = false) => {
    if (isRelatedService) {
      setRelatedQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        if (newQuantities[serviceId] > 0) {
          newQuantities[serviceId] -= 1;

          if (newQuantities[serviceId] === 0) {
            setFormData((prevFormData) =>
              prevFormData.filter(
                (item) =>
                  item.service_name !==
                  RelatedServiceData.related_service.find(
                    (item) => item.id === serviceId
                  ).related_service_name
              )
            );
          } else {
            updateFormData(serviceId, newQuantities[serviceId], true);
          }
        }
        return newQuantities;
      });
    } else {
      setServiceQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        if (newQuantities[serviceId] > 0) {
          newQuantities[serviceId] -= 1;

          if (newQuantities[serviceId] === 0) {
            setFormData((prevFormData) =>
              prevFormData.filter(
                (item) =>
                  item.service_name !==
                  Data.services.find((service) => service.id === serviceId)
                    .service_name
              )
            );
          } else {
            updateFormData(serviceId, newQuantities[serviceId]);
          }
        }
        return newQuantities;
      });
    }
  };
  return (
    <div className="">
      {showRelatedService ? (
        <div>
          <div
            onClick={handleCloseRelatedService}
            className="flex justify-start cursor-pointer items-center gap-2 bg-yellow-600/40 px-2 py-1 text-black/80 rounded-lg"
          >
            <div className="rotate-180">
              <FaArrowRightLong />
            </div>
            <div>{RelatedServiceData.service_name}</div>
          </div>
          <div className="">
            {RelatedServiceData.related_service.map((item) => (
              <div
                key={item.id}
                className="flex justify-between items-center border-b border-0.5 py-3"
              >
                <div className="flex items-start justify-start gap-3">
                  <div className="">
                    <p className="font-semibold">{item.related_service_name}</p>
                    <p>
                      ৳
                      {item.related_service_price -
                        RelatedServiceData.related_service_discount}{" "}
                      {RelatedServiceData.related_service_discount > 0 && (
                        <span className="line-through">
                          ৳{item.related_service_price}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex gap-2 items-center">
                    <div
                      onClick={() => handleDecrement(item.id, true)}
                      className="flex justify-center items-center bg-yellow-600 text-white w-[30px] h-[30px] cursor-pointer rounded-lg"
                    >
                      <BiMinus className="text-3xl" />
                    </div>
                    <input
                      type="number"
                      value={relatedQuantities[item.id] || 0}
                      className="border w-[30px] h-[30px] focus:outline-none text-lg text-black/70 text-center font-semibold"
                      readOnly
                    />
                    <div
                      onClick={() => handleIncrement(item.id, true)}
                      className="flex justify-center items-center bg-yellow-600 text-white w-[30px] h-[30px] cursor-pointer rounded-lg"
                    >
                      <BiPlus className="text-3xl" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          {Data.services.length > 0 ? (
            <div>
              {Data.services.map((items) => (
                <div key={items.id} className="border-b border-0.5 py-3 ">
                  <div className="flex justify-between items-center">
                    <div className="flex items-start justify-start gap-3">
                      <div className="w-[50px] h-[50px]">
                        <img
                          className="w-full h-full object-cover"
                          src={items.serviceImageUrl}
                          alt={items.service_name}
                        />
                      </div>
                      <div>
                        <p className="text-lg font-semibold">
                          {items.service_name}
                        </p>
                        {items.highlighted_one && (
                          <p className="text-xs text-black/50">
                            {items.highlighted_one}
                          </p>
                        )}
                        {items.highlighted_two && (
                          <p className="text-xs text-black/50">
                            {items.highlighted_two}
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      {items && items.related_service.length > 0 ? (
                        <div>
                          <div
                            onClick={() => handleShowRelatedService(items.id)}
                            className="flex gap-2 justify-center cursor-pointer items-center px-2 py-1 rounded-lg bg-yellow-600 text-white"
                          >
                            <div>Next</div>
                            <div>
                              <FaArrowRightLong />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex gap-2 items-center">
                          <div
                            onClick={() => handleDecrement(items.id)}
                            className="flex justify-center items-center bg-yellow-600 text-white w-[30px] h-[30px] cursor-pointer rounded-lg"
                          >
                            <BiMinus className="text-3xl" />
                          </div>
                          <input
                            type="number"
                            value={serviceQuantities[items.id] || 0}
                            className="border w-[30px] h-[30px] focus:outline-none text-lg text-black/70 text-center font-semibold"
                            readOnly
                          />
                          <div
                            onClick={() => handleIncrement(items.id)}
                            className="flex justify-center items-center bg-yellow-600 text-white w-[30px] h-[30px] cursor-pointer rounded-lg"
                          >
                            <BiPlus className="text-3xl" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>No service data found</div>
          )}
        </>
      )}
    </div>
  );
};

export default ServiceQuantity;
