import { configureStore } from "@reduxjs/toolkit";
import websiteReducer from "../admin/websiteSlice/websiteSlice";
import ImageReducer from "../admin/ImageSelectedSlice/ImageSelectedSlice";
import otpReducer from "../frontend/otpSlice/otpSlice";
import loginReducer from "../frontend/loginSlice/loginSlice";
import allCategoryReducer from "../frontend/allCategorySlice/allCategorySlice";
export const store = configureStore({
  reducer: {
    website: websiteReducer,
    imageSelected: ImageReducer,
    phoneNumber: otpReducer,
    login: loginReducer,
    allCategory: allCategoryReducer,
  },
});
