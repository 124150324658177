import React from "react";
import Container from "../../../utils/Container";

const TermsAndCondition = () => {
  return (
    <Container>
      <div
        className="p-6 max-w-3xl mx-auto space-y-6 text-gray-800"
        dangerouslySetInnerHTML={{
          __html: `
          <h1 class="text-3xl font-bold text-gray-900 mb-4">Terms and Conditions for Nirapod.xyz On-Demand Pest Control Services</h1>
          <p class="text-lg leading-relaxed">Welcome to Nirapod.xyz, your trusted on-demand pest control service provider in Bangladesh. By accessing and using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully. If you do not agree with any part of these terms, please refrain from using our services.</p>
          
          <h2 class="text-2xl font-semibold mt-8 mb-4">1. Services Provided</h2>
          <p class="text-lg leading-relaxed">Nirapod.xyz offers on-demand pest control services designed to eliminate or manage pests in residential and commercial properties. Our services include but are not limited to treatments for ants, cockroaches, rodents, termites, mosquitoes, bed bugs, and other common pests. All services are conducted by trained professionals using approved pest control products and methods.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">2. Service Availability</h2>
          <p class="text-lg leading-relaxed">Services are available within designated areas across Bangladesh. Nirapod.xyz reserves the right to limit or extend service areas based on operational feasibility. Service appointments are subject to availability and may be rescheduled due to unforeseen circumstances, including weather conditions, public holidays, or local restrictions.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">3. Booking and Confirmation</h2>
          <p class="text-lg leading-relaxed">Bookings can be made through the Nirapod.xyz website, app, or authorized customer service channels. Confirmation of booking is subject to availability and successful payment, if applicable. Users will receive a booking confirmation via email or SMS, including the date, time, and details of the selected service.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">4. Pricing and Payments</h2>
          <p class="text-lg leading-relaxed">Service charges are based on the type of service, property size, and level of infestation. Prices are displayed on our website and app and are subject to change without prior notice. Payments must be made through authorized payment methods. We accept various forms of payment, including cash, digital payments, and mobile financial services (MFS). Full payment is required prior to service initiation unless otherwise agreed upon.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">5. Cancellation and Refund Policy</h2>
          <ul class="list-disc list-inside space-y-2 pl-4 text-lg">
            <li><strong>Cancellations:</strong> Users may cancel or reschedule a booking at least 24 hours prior to the scheduled appointment. Cancellations made less than 24 hours in advance may incur a cancellation fee.</li>
            <li><strong>Refunds:</strong> Refunds may be issued in case of service cancellation by Nirapod.xyz due to unavoidable circumstances. Refunds are subject to review and approval by Nirapod.xyz management and may take up to 10 working days to process.</li>
          </ul>

          <h2 class="text-2xl font-semibold mt-8 mb-4">6. User Responsibilities</h2>
          <p class="text-lg leading-relaxed">Users must provide accurate information about the property and the pest problem when booking. Misrepresentation may result in delays or additional charges. Users must prepare the property as instructed by Nirapod.xyz staff to ensure safe and effective treatment. This may include removing pets, food items, and sensitive belongings from the treatment area.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">7. Safety and Liability</h2>
          <p class="text-lg leading-relaxed">Nirapod.xyz uses safe, approved, and environmentally friendly pest control products. However, users must follow all provided safety guidelines to avoid exposure risks. Nirapod.xyz is not liable for any adverse effects resulting from non-compliance with safety instructions, allergies, or sensitivities to pest control chemicals. Any damage or loss resulting from unauthorized modifications to the pest control methods, user negligence, or failure to comply with pre-service instructions will not be covered by Nirapod.xyz.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">8. Warranties and Guarantees</h2>
          <p class="text-lg leading-relaxed">Nirapod.xyz provides limited service warranties based on the type of pest treatment and level of infestation. Warranty details will be specified in the service invoice. Follow-up services, if required within the warranty period, are provided at no additional cost unless otherwise specified. Warranty is void if users apply unauthorized pest control products or modify treated areas.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">9. Privacy and Data Security</h2>
          <p class="text-lg leading-relaxed">User data collected during the booking process will be handled in accordance with Nirapod.xyz’s Privacy Policy. Personal information will not be shared with third parties without user consent, except as required by law. Nirapod.xyz employs industry-standard data protection practices to safeguard user information. However, we do not assume liability for unauthorized access resulting from user negligence.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">10. Modifications to Terms</h2>
          <p class="text-lg leading-relaxed">Nirapod.xyz reserves the right to update these Terms and Conditions at any time. Users will be notified of significant changes through the website or app. Continued use of our services after modifications implies acceptance of the revised terms.</p>

          <h2 class="text-2xl font-semibold mt-8 mb-4">11. Governing Law and Dispute Resolution</h2>
          <p class="text-lg leading-relaxed">These Terms and Conditions are governed by the laws of Bangladesh. Any disputes arising from or related to the use of our services shall be resolved in accordance with the laws of Bangladesh. In case of any dispute, users are encouraged to contact Nirapod.xyz customer support for assistance in resolving the matter amicably.</p>
          
          <p class="text-lg leading-relaxed">For any questions or clarifications regarding these Terms and Conditions, please contact our support team at <a href="mailto:support@nirapod.xyz" class="text-blue-500 hover:underline">support@nirapod.xyz</a> or call our helpline.</p>
        `,
        }}
      />
    </Container>
  );
};

export default TermsAndCondition;
