import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../axiosInstance";

const ServiceRequest = () => {
  const [data, setData] = useState([]);
  const handleView = () => {
    AxiosInstance.get("/request/service-requests")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleView();
  }, []);
  return (
    <div>
      <div>
        <h1 className="text-xl font-bold text-center">Service Requested</h1>
      </div>
      <div className="mt-4">
        <table className="w-full">
          <thead>
            <tr className="text-center text-sm">
              <th className="border px-4 py-1">Sr. No.</th>
              <th className="border px-4 py-1">Full Name</th>
              <th className="border px-4 py-1">Phone Number</th>
              <th className="border px-4 py-1">Address</th>
              <th className="border px-4 py-1">Service Details</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <tr key={index}>
                  <td className="border px-4 py-1">{index + 1}</td>
                  <td className="border px-4 py-1">{item.full_name}</td>
                  <td className="border px-4 py-1">{item.phone_number}</td>
                  <td className="border px-4 py-1">{item.address}</td>
                  <td className="border px-4 py-1">{item.service_details}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServiceRequest;
