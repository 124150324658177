import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HeroSearchBar = ({ searchInput, setSearchInput }) => {
  return (
    <>
      <div className="mx-auto mt-5 flex items-center justify-center w-[400px] border px-2 rounded-lg bg-white">
        {/* Search Input */}
        <div>
          <input
            type="text"
            className="px-3 text-gray-500 font-semibold py-2 w-[350px] focus:outline-none"
            placeholder="Search Any Services"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)} // Update the search input state
          />
        </div>
        <div className="text-2xl bg-yellow-600 p-1 rounded-lg text-white cursor-pointer">
          <BiSearch />
        </div>

        {/* Display the filtered results if searchInput has a value */}
      </div>
    </>
  );
};

export default HeroSearchBar;
