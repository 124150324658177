import React, { useEffect, useState } from "react";
import TableContent from "../../../components/admin/table/content/TableContent";
import AxiosInstance from "../../../axiosInstance";
import TableHeader from "../../../components/admin/table/table-header/TableHeader";
import TablePage from "../../../components/admin/table/pagination/TablePagination";

const SubCategory = () => {
  const [CategoryData, setCategoryData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const handleViewData = () => {
    AxiosInstance.get(
      `/subcategory/sub-categories?page=${page + 1}&limit=${rowsPerPage}`
    )
      .then((res) => {
        setCategoryData(res.data.subcategories);
        setTotalItems(res.data.totalItems);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleViewData();
  }, [page, rowsPerPage]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData =
    CategoryData?.length > 0
      ? CategoryData.filter((item) =>
          item.sub_category_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      : [];

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to the first page
  };

  const tableHead = [
    "Sr. No.",
    "Sub Category Name",
    "Sub Category Icon",
    "Sub Category Banner",
    "Main Category",
    "Status",
    "Actions",
  ];

  const tableData = [
    (item, index) => `<td>${index + 1}</td>`,
    (item) => `<td>${item.sub_category_name}</td>`,
    (item) =>
      `<td>
       <div class='flex justify-center items-center gap-3'>
    <img class='w-[50px] h-[50px] object-cover rounded-full' src="${item.subCatIconUrl}" alt="${item.sub_category_name}" />
     </div>
    </td>`,
    (item) =>
      `<td>
    <div class='flex justify-center items-center gap-3'>
    <img class='w-[50px] h-[50px] object-contain' src="${item.subCatBannerUrl}" alt="${item.sub_category_name}" />
    </div>
    </td>`,
    (item) => `<td>${item.category_name}</td>`,
    (item) => `<td>Active</td>`,
  ];
  return (
    <div className="w-full h-auto px-6 py-6 shadow-md rounded-lg">
      <TableHeader
        tableName="Sub Category"
        tableSubtitle="All Sub Categories are here."
        buttonLink="/admin/subcategory/add-subcategory"
        buttonName="Add Sub Category"
        placeHolder="Sub Category"
        searchQuery={handleSearch}
      />
      <TableContent
        tableData={tableData}
        filteredData={filteredData} // Filtered data based on search query
        tableHead={tableHead}
        editLink="/admin/subcategory/edit-subcategory/"
        deleteApi="/subcategory/delete-sub-category/"
        deletedText="Sub category has been deleted."
        handleViewData={handleViewData}
      />
      <TablePage
        count={totalItems} // Total items count for pagination
        page={page} // Current page
        rowsPerPage={rowsPerPage} // Rows per page
        handleChangePage={handleChangePage} // Function to handle page change
        handleChangeRowsPerPage={handleChangeRowsPerPage} // Function to change rows per page
      />
    </div>
  );
};

export default SubCategory;
