import React, { useState } from "react";
import TableOrder from "./components/order-table/TableOrder";

const Order = () => {
  const [Toggle, setToggle] = useState(1);
  return (
    <div>
      <div className="grid grid-cols-3 gap-x-5">
        <div
          onClick={() => setToggle(1)}
          className="text-center py-2 bg-slate-500/50 rounded-xl cursor-pointer"
        >
          All Orders
        </div>
        <div
          onClick={() => setToggle(2)}
          className="text-center py-2 bg-slate-500/50 rounded-xl cursor-pointer"
        >
          Complete Orders
        </div>
        <div
          onClick={() => setToggle(3)}
          className="text-center py-2 bg-slate-500/50 rounded-xl cursor-pointer"
        >
          Cancel Orders
        </div>{" "}
      </div>
      {Toggle === 1 && <TableOrder Toggle={Toggle} />}
      {Toggle === 2 && <TableOrder Toggle={Toggle} />}
      {Toggle === 3 && <TableOrder Toggle={Toggle} />}
    </div>
  );
};

export default Order;
