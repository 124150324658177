import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  setBlogImageSelected,
  setCategoryBanner,
  setCategoryIcon,
  setFooterBanner,
  setHeroBanner,
  setImageSelected,
  setMultipleImageSelected,
  setServiceImage,
  setSubCategoryBanner,
  setSubCategoryIcon,
} from "../../../context/admin/ImageSelectedSlice/ImageSelectedSlice";

import { TiTick } from "react-icons/ti";

const ViewImageToSelect = ({
  categoryIconNeeded,
  categoryBannerNeeded,
  subCategoryIconNeeded,
  subCategoryBannerNeeded,
  heroBannerNeeded,
  footerBannerNeeded,
  seviceImage,
  singleImageSelector,
  multipleImageSelector,
  handleCloseFileManager,
  handleCloseManagerforBanner,
  handleCloseFileManagerForIcon,
  handleCloseFileManagerForBanner,
  blogImageNeeded,
}) => {
  const dispatch = useDispatch();
  const selectedImage = useSelector(
    (state) => state.imageSelected.imageSelected
  );
  const [fileData, setFileData] = useState([]);

  const handleViewData = () => {
    AxiosInstance.get("/file/view-files")
      .then((res) => {
        setFileData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleViewData();
  }, []);

  const isImage = (fileName) =>
    /\.(jpg|jpeg|png|gif|webp|svg)$/i.test(fileName);

  const imageFiles = fileData.filter((file) => isImage(file.file_name));
  const handleSelect = (id) => {
    if (categoryIconNeeded === true) {
      dispatch(setCategoryIcon(id));
      handleCloseFileManager();
    }
    if (categoryBannerNeeded === true) {
      dispatch(setCategoryBanner(id));
      handleCloseManagerforBanner();
    }
    if (subCategoryIconNeeded === true) {
      dispatch(setSubCategoryIcon(id));
      handleCloseFileManagerForIcon();
    }
    if (subCategoryBannerNeeded === true) {
      dispatch(setSubCategoryBanner(id));
      handleCloseFileManagerForBanner();
    }
    if (seviceImage === true) {
      dispatch(setServiceImage(id));
      handleCloseFileManagerForIcon();
    }
    if (heroBannerNeeded === true) {
      dispatch(setHeroBanner(id));
    }
    if (footerBannerNeeded === true) {
      dispatch(setFooterBanner(id));
    }
    if (blogImageNeeded === true) {
      dispatch(setBlogImageSelected(id));
      handleCloseFileManager();
    }
  };
  const isImageSelected = (imageId) => imageId === selectedImage;
  return (
    <div className="mt-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 h-[450px] py-5 overflow-y-scroll overflow-x-hidden px-3">
        {imageFiles.map((file) => (
          <div
            onClick={() => handleSelect(file.id)}
            key={file.id}
            className={`relative  p-2 rounded-md ${
              isImageSelected(file.id) ? "border-2 border-green-500" : "border"
            } `}
          >
            <div>
              {isImageSelected(file.id) ? (
                <span className="bg-green-500 rounded-full  flex justify-center items-center text-white w-7 h-7 text-2xl border absolute -top-2 -right-2 z-30">
                  <TiTick />
                </span>
              ) : null}
              <img
                src={file.fileUrl}
                alt={file.file_name}
                className="w-full h-40 object-contain mb-2"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewImageToSelect;
