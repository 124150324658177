import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import TabToUpload from "./TabToUpload";
import ViewImageToSelect from "./ViewImageToSelect";

const FileManagerPopup = ({
  handleCloseFileManager,
  singleImageSelector,
  categoryIconNeeded,
  categoryBannerNeeded,
  subCategoryIconNeeded,
  subCategoryBannerNeeded,
  seviceImage,
  heroBannerNeeded,
  footerBannerNeeded,
  handleCloseManagerforBanner,
  handleCloseFileManagerForIcon,
  handleCloseFileManagerForBanner,
  blogImageNeeded,
}) => {
  const [Tab, setTab] = useState(2);
  const handleClose = () => {
    if (categoryIconNeeded === true) {
      handleCloseFileManager();
    }
    if (categoryBannerNeeded === true) {
      handleCloseManagerforBanner();
    }
    if (subCategoryIconNeeded === true) {
      handleCloseFileManagerForIcon();
    }
    if (subCategoryBannerNeeded === true) {
      handleCloseFileManagerForBanner();
    }
    if (seviceImage === true) {
      handleCloseFileManagerForIcon();
    }
    if (blogImageNeeded === true) {
      handleCloseFileManager();
    }
  };
  return (
    <div>
      <div className="absolute top-0 left-0 w-screen h-screen bg-black/20 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out  opacity-100">
        <div className="bg-white relative p-6 rounded-md shadow-md w-[850px] h-auto  transform transition-all  duration-300 ease-out scale-95">
          <button
            onClick={handleClose}
            className="absolute -top-4 text-3xl -right-3 bg-white rounded-full text-rose-600 cursor-pointer"
          >
            <MdCancel />
          </button>
          <div className="w-full">
            <div className="grid grid-cols-2">
              <div className="border px-4 text-center py-2">
                <button onClick={() => setTab(1)}>Upload Image</button>
              </div>
              <div className="border px-4 text-center py-2">
                <button onClick={() => setTab(2)}>Select Image</button>
              </div>
            </div>
            {Tab === 1 && <TabToUpload setTab={setTab} />}
            {Tab === 2 && (
              <ViewImageToSelect
                categoryIconNeeded={categoryIconNeeded}
                categoryBannerNeeded={categoryBannerNeeded}
                subCategoryIconNeeded={subCategoryIconNeeded}
                subCategoryBannerNeeded={subCategoryBannerNeeded}
                heroBannerNeeded={heroBannerNeeded}
                footerBannerNeeded={footerBannerNeeded}
                seviceImage={seviceImage}
                blogImageNeeded={blogImageNeeded}
                handleCloseFileManager={handleCloseFileManager}
                singleImageSelector={singleImageSelector}
                handleCloseManagerforBanner={handleCloseManagerforBanner}
                handleCloseFileManagerForIcon={handleCloseFileManagerForIcon}
                handleCloseFileManagerForBanner={
                  handleCloseFileManagerForBanner
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileManagerPopup;
