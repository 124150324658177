import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import CompanyProfilePDF from "../../../assets/frontend/pdf/company_profile.pdf";
import Container from "../../../utils/Container";

const CompanyProfile = () => {
  return (
    <Container>
      <div className="pdf-viewer-container w-full">
        <object
          data={CompanyProfilePDF}
          type="application/pdf"
          width="100%"
          height="100vh"
        >
          <p>
            Your browser does not support PDFs.
            <a href="/company-profile.pdf">Download the PDF</a>.
          </p>
        </object>
      </div>
    </Container>
  );
};

export default CompanyProfile;
