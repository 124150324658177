import React, { useState } from "react";
import AddCategory from "../add-category/AddCategory";
import FileManagerPopup from "../../../../../components/admin/FileManager/FileManagerPopup";
import { FaEdit } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import EditCategory from "../edit-category/EditCategory";
import AxiosInstance from "../../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";

const CategoryTable = ({ CategoryData, handleViewData }) => {
  const [AddCategoryPopup, setAddCategoryPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query
  const [ShowEdit, setShowEdit] = useState(false);
  const [EditId, setEditId] = useState("");
  const handleOpen = () => {
    setAddCategoryPopup(true);
  };
  const handleClose = () => {
    setAddCategoryPopup(false);
  };

  // Handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the data based on the search query
  const filteredData = CategoryData.filter((item) =>
    item.category_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleShowEdit = (id) => {
    setShowEdit(true);
    setEditId(id);
  };
  const handleCloseEdit = () => {
    setShowEdit(false);
  };
  const handleDelete = (id) => {
    AxiosInstance.delete(`/category/delete-category/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Category has been deleted.", {
            position: "bottom-center",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          handleViewData();
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {ShowEdit && (
        <EditCategory
          handleCloseEdit={handleCloseEdit}
          handleViewData={handleViewData}
          EditId={EditId}
        />
      )}
      <div className="w-full h-auto bg-white shadow-md px-4 py-4 rounded-lg">
        <div className="flex justify-between items-center">
          <div>
            <input
              type="text"
              className="border px-4 py-2 rounded-2xl w-[350px] focus:outline-none"
              placeholder="Search by category name"
              value={searchQuery}
              onChange={handleSearch} // Update searchQuery on input change
            />
          </div>
          <div>
            <button
              onClick={handleOpen}
              className="text-white text-sm bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] px-4 py-2 rounded-2xl"
            >
              Add Category
            </button>
            {AddCategoryPopup && (
              <AddCategory
                handleViewData={handleViewData}
                handleClose={handleClose}
              />
            )}
          </div>
        </div>
        <div>
          <table className="w-full mt-5">
            <thead>
              <tr className="text-center text-sm">
                <th className="border px-4 py-2">Sr. No.</th>
                <th className="border px-4 py-2">Category Name</th>
                <th className="border px-4 py-2">Category Icon</th>
                <th className="border px-4 py-2">Category Banner</th>
                <th className="border px-4 py-2">Status</th>
                <th className="border px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index} className="text-center text-sm">
                  <td className="border px-4 py-2">{index + 1}</td>
                  <td className="border px-4 py-2">{item.category_name}</td>
                  <td className="border px-4 py-2 ">
                    <div className="flex justify-center items-center">
                      <img
                        className="w-[50px] h-[50px] object-cover rounded-full"
                        src={item.categoryIconUrl}
                        alt={item.category_name}
                      />
                    </div>
                  </td>
                  <td className="border px-4 py-2">
                    <div className="flex justify-center items-center">
                      <img
                        className="w-[50px] h-[50px] object-cover rounded-full"
                        src={item.categoryBannerUrl}
                        alt={item.category_name}
                      />
                    </div>
                  </td>
                  <td className="border px-4 py-2">Active</td>
                  <td className="border px-4 py-2 ">
                    <div className="flex justify-center items-center gap-3">
                      <button onClick={() => handleShowEdit(item.id)}>
                        <FaEdit className="text-lg text-green-700" />
                      </button>
                      <button>
                        <TiDelete
                          onClick={() => handleDelete(item.id)}
                          className="text-2xl text-red-500"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CategoryTable;
