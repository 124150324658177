import React from "react";
import Container from "../../../../utils/Container";
import SocialMedia from "./SocialMedia";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ISOLogo from "../../../../assets/frontend/logo/iso.png"
const FooterContent = () => {
  const { phone_number, alternative_phone_number, email, address } =
    useSelector((state) => state.website);
  return (
    <Container>
      <div className="grid grid-flow-row grid-cols-2 md:grid-cols-3  lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-10 gap-x-20 md:gap-x-5 ">
        <div>
          <p className="font-bold">Contact</p>
          <p className="mt-3">
            {phone_number}, {alternative_phone_number}
          </p>
          <div className="mt-3">
            <p className="font-medium">Corporate Address</p>
            <p>{address}</p>
          </div>
          <div className="mt-5">
            <p className="font-semibold">Trade License No</p>
            <p>TRAD/DNCC/145647/2022</p>
          </div>
          <div className="mt-5">
            <p className="font-semibold">ISO Certificate</p>
            <div className="flex justify-start items-center gap-x-2">
              <div>
                <img className="w-[25px]" src={ISOLogo} alt="" />

              </div>
              <div>
              <p>ISO 9001:2015</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="font-bold">Quick Links</p>
          <Link to="https://blog.nirapod.xyz" target="_blank">
            <p className="mt-3">Blog</p>
          </Link>
          <Link to="/terms-and-conditions">
            <p className="mt-3">Terms and Conditions</p>
          </Link>
        </div>
        <div>
          <p className="font-bold">About Nirapod</p>

          <Link to="/privacy-policy">
            <p className="mt-3">Privacy Policy</p>
          </Link>
          <Link to="/refund-policy">
            <p className="mt-3">Refund and Return Policy</p>
          </Link>
        </div>
        <div>
          <p className="font-bold">Connect With Us</p>
          <SocialMedia />
        </div>
      </div>
    </Container>
  );
};

export default FooterContent;
