import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import ServiceQuantity from "./components/ServiceQuanity/ServiceQuantity";
import CartDetails from "./components/cartDetails/CartDetails";
import Login from "../auth/login/Login";
import { FaMinusCircle } from "react-icons/fa";

const AddToCart = ({ handleClose, Data, relatedId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ShowLogin, setShowLogin] = useState(false);
  const getToken = sessionStorage.getItem("accessToken");
  const [data, setData] = useState(() => {
    const savedData = sessionStorage.getItem("selectedServices");
    return savedData ? JSON.parse(savedData) : [];
  });

  const handleCheckout = () => {
    if (getToken) {
      setIsLoading(true);
      setTimeout(() => {
        window.location.href = "/checkout";
        setIsLoading(false);
      }, 3000);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setShowLogin(true);
      }, 3000);
    }
  };

  const handleCancel = () => {
    setShowLogin(false);
    setIsLoading(false);
  };

  const updateData = () => {
    try {
      const storedData = sessionStorage.getItem("selectedServices");
      if (storedData) {
        setData(JSON.parse(storedData));
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Failed to parse sessionStorage data:", error);
    }
  };

  useEffect(() => {
    updateData();

    const handleStorageEvent = (event) => {
      if (event.key === "selectedServices") {
        updateData();
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [data]);

  useEffect(() => {
    const handleSessionStorageChange = (event) => {
      if (event.key === "selectedServices") {
        updateData();
      }
    };

    const originalSetItem = sessionStorage.setItem;
    sessionStorage.setItem = function (key, value) {
      const event = new Event("storage");
      event.key = key;
      event.newValue = value;
      document.dispatchEvent(event);
      originalSetItem.apply(this, arguments);
    };

    document.addEventListener("storage", handleSessionStorageChange);

    return () => {
      document.removeEventListener("storage", handleSessionStorageChange);
      sessionStorage.setItem = originalSetItem;
    };
  }, []);

  const calculateSubTotal = () => {
    return data.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  };

  const subTotal = calculateSubTotal();
  const handleRemove = (service_name) => {
    const selectedServices =
      JSON.parse(sessionStorage.getItem("selectedServices")) || [];

    const updatedServices = selectedServices.filter(
      (service) => service.service_name !== service_name
    );

    sessionStorage.setItem("selectedServices", JSON.stringify(updatedServices));
    updateData();
  };
  return (
    <>
      <div className="absolute top-0 left-0 z-[999] ">
        {ShowLogin && <Login handleCancel={handleCancel} />}
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-black/20 flex justify-center items-center z-50">
        <div className="bg-white relative rounded-md shadow-md w-[850px] min-h-[630px] md:min-h-[450px] transform transition-all duration-300 ease-out scale-95">
          <div className="w-full h-full px-4 py-3 text-lg font-semibold bg-white shadow-md rounded-lg text-center">
            Add Your Desired Services
          </div>
          <button
            onClick={handleClose}
            className="absolute -top-4 text-3xl -right-3 bg-white rounded-full text-rose-600 cursor-pointer"
          >
            <MdCancel />
          </button>
          <div className="flex justify-between flex-col md:flex-row mt-3">
            <div className="h-[450px] overflow-y-scroll w-full md:w-1/2 border-r px-4">
              <ServiceQuantity
                relatedId={relatedId}
                Data={Data}
                updateData={updateData}
              />
            </div>
            <div className="fixed bottom-0 w-full block md:hidden">
              <div className="flex justify-between items-center px-4 py-2 border-t ">
                <p className="text-md font-semibold">Sub Total:</p>
                <p className="text-md font-semibold">৳{subTotal}</p>
              </div>
              <button
                onClick={handleCheckout}
                className="bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] text-white text-center font-semibold p-4 w-full"
              >
                {isLoading ? "Loading..." : "Checkout"}
              </button>
            </div>
            <div className="h-[450px] w-full md:w-1/2 hidden md:block">
              <div className="flex flex-col justify-between h-full">
                <div className="flex-1 h-[400px] overflow-y-scroll px-4 py-3">
                  {data.length > 0 ? (
                    <div>
                      {data.map((item, index) => (
                        <div
                          key={index}
                          className="relative flex justify-between items-center gap-3 mb-5 bg-slate-200/50 px-2 py-2 rounded-lg"
                        >
                          <span
                            onClick={() => handleRemove(item.service_name)} // Use an anonymous function to avoid immediate execution
                            className="absolute -top-2 -right-2 text-red-600"
                          >
                            <FaMinusCircle />
                          </span>
                          <div>
                            <p className="text-md font-medium">
                              {item.service_name}
                            </p>
                            <p>Quantity: {item.quantity}</p>
                          </div>
                          <div>
                            <p className="text-md text-green-800 text-center font-semibold">
                              ৳{item.price - item.related_service_discount}.00
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No services selected</p>
                  )}
                </div>
                <div className="">
                  <div className="flex justify-between items-center px-4 py-2 border-t ">
                    <p className="text-md font-semibold">Sub Total:</p>
                    <p className="text-md font-semibold">৳{subTotal}</p>
                  </div>

                  <div className="mt-4">
                    <button
                      onClick={handleCheckout}
                      className="bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] text-white text-center font-semibold p-4 w-full"
                    >
                      {isLoading ? "Loading..." : "Checkout"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddToCart;
