import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";
import { getAddress } from "../../../../api/frontend/frontendAPI";

const AddAddress = ({ setAddress, setShowAddAddress }) => {
  const [FormData, setFormData] = useState({
    house_no: "",
    road: "",
    city: "",
    status: 1,
    user_id: "",
  });

  useEffect(() => {
    const userInfo = sessionStorage.getItem("userInfo");
    const userInfor = JSON.parse(userInfo);
    if (userInfo) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user_id: userInfor.id,
      }));
    }
  }, []);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const handleSubmit = () => {
    AxiosInstance.post("/address/add-address", FormData)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Address has been added", {
            position: "bottom-center",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          getAddress(setAddress);
          setFormData({
            house_no: "",
            road: "",
            city: "",
            status: 1,
            user_id: "",
          });
          setShowAddAddress(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="border-2 border-yellow-600 mt-2 px-4 py-4 rounded-lg">
      <p className="font-semibold text-lg text-start">Add Address</p>
      <form>
        <div className="mt-4">
          <label htmlFor="house_no">House No. </label>
          <input
            type="text"
            id="house_no"
            value={FormData.house_no}
            onChange={handleChange}
            placeholder="House No."
            className="border w-full px-4 py-1 rounded-md focus:outline-none"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="road">Road </label>
          <input
            type="text"
            id="road"
            value={FormData.road}
            onChange={handleChange}
            placeholder="Road"
            className="border w-full px-4 py-1 rounded-md focus:outline-none"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="city">City </label>
          <input
            type="text"
            id="city"
            value={FormData.city}
            onChange={handleChange}
            placeholder="City"
            className="border w-full px-4 py-1 rounded-md focus:outline-none"
          />
        </div>
      </form>
      <div className="mt-4 flex justify-end">
        <button
          onClick={handleSubmit}
          className="px-4 py-2 text-xs bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 duration-300 text-white rounded-lg font-semibold"
        >
          Add Address
        </button>
      </div>
    </div>
  );
};

export default AddAddress;
