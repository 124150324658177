import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { HiOutlineDotsVertical } from "react-icons/hi";
import AxiosInstance from "../../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaDownload } from "react-icons/fa";

const FileActions = ({ FileId, handleViewData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    AxiosInstance.delete(`/file/delete-file/${FileId}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("File has been deleted.", {
            position: "top-right",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          handleViewData();
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDownload = () => {
    AxiosInstance.get(`/file/download/${FileId}`, { responseType: "blob" })
      .then((res) => {
        if (res.status === 200) {
          const file = new Blob([res.data], {
            type: res.headers["content-type"],
          });

          // Create a link element
          const link = document.createElement("a");
          link.href = URL.createObjectURL(file);
          link.download =
            res.headers["content-disposition"]?.split("filename=")[1] ||
            "downloaded-file";

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);

          toast.success("File has been downloaded.", {
            position: "top-right",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });

          handleViewData();
          handleClose();
        }
      })
      .catch((err) => {
        toast.error("Failed to download file.", {
          position: "top-right",
          autoClose: 1200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="File Actions">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <HiOutlineDotsVertical className="text-xl" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleDelete}>
          <MdDelete className="text-xl mr-2 text-red-500" />
          Delete
        </MenuItem>
        <MenuItem onClick={handleDownload}>
          <FaDownload className="text-lg mr-2 text-green-500" />
          Download
        </MenuItem>
      </Menu>
    </>
  );
};

export default FileActions;
