import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { store } from "./context/store/store";
import Loader from "./components/frontend/loader/Loader";

const DelayedSuspense = ({ children, delay }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, delay);

    // Cleanup the timer on unmount
    return () => clearTimeout(timer);
  }, [delay]);

  if (!show) {
    return <Loader />; // Fallback UI during the delay
  }

  return <>{children}</>;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <DelayedSuspense delay={2000}>
          <Suspense fallback={<div className="bg-black">Loading...</div>}>
            <App />
          </Suspense>
        </DelayedSuspense>
        <ToastContainer />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);
