import IcDashboard from "../../../assets/admin/icon/IcDashboard";
import IcSettings from "../../../assets/admin/icon/IcSettings";
import IcUtilities from "../../../assets/admin/icon/IcUtilities";

const sidebarMenu = [
  {
    id: 1,
    header: "Dashboard",
    name: "Dashboard",
    url: "/admin/order-management/order",
    icon: <IcDashboard />,
    permission_name: "view_dashboard",
  },
  {
    id: 2,
    header: "Website Settings",
    name: "Settings",
    url: "#",
    icon: <IcSettings />,
    permission_name: "view_settings",
    submenu: [
      {
        id: 3,
        name: "SMS Settings",
        url: "/admin/settings/sms",
        permission_name: "view_general_settings",
      },
      {
        id: 4,
        name: "Website Settings",
        url: "/admin/settings/website",
        permission_name: "view_user_settings",
      },
    ],
  },
  {
    id: 3,
    header: "Service Management",
    name: "Services",
    url: "#", // Placeholder if the main item doesn't have a direct link
    icon: <IcUtilities />,
    permission_name: "view_settings",
    submenu: [
      {
        id: 3,
        name: "Category",
        url: "/admin/category/category",
        permission_name: "view_general_settings",
      },
      {
        id: 4,
        name: "Sub Category",
        url: "/admin/subcategory/subcategory",
        permission_name: "view_user_settings",
      },
      {
        id: 4,
        name: "Services",
        url: "/admin/services/services",
        permission_name: "view_user_settings",
      },
    ],
  },
  {
    id: 4,
    header: "Order Management",
    name: "Orders",
    url: "#", // Placeholder if the main item doesn't have a direct link
    icon: <IcUtilities />,
    permission_name: "view_settings",
    submenu: [
      {
        id: 3,
        name: "View Orders",
        url: "/admin/order-management/order",
        permission_name: "view_general_settings",
      },
      {
        id: 4,
        name: "Sub Category",
        url: "/admin/subcategory/subcategory",
        permission_name: "view_user_settings",
      },
      {
        id: 4,
        name: "Services",
        url: "/admin/services/services",
        permission_name: "view_user_settings",
      },
      {
        id: 4,
        name: "Services Request",
        url: "/admin/order-management/service-request",
        permission_name: "view_user_settings",
      },
    ],
  },
  {
    id: 5,
    header: "Frontend Settings",
    name: "Website Control",
    url: "#", // Placeholder if the main item doesn't have a direct link
    icon: <IcUtilities />,
    permission_name: "view_settings",
    submenu: [
      {
        id: 3,
        name: "Display Services",
        url: "/admin/frontend/display/services",
        permission_name: "view_general_settings",
      },
      {
        id: 4,
        name: "User Settings",
        url: "/admin/settings/users",
        permission_name: "view_user_settings",
      },
      {
        id: 5,
        name: "Banner Section",
        url: "/admin/frontend/banners",
        permission_name: "view_user_settings",
      },
    ],
  },

  {
    id: 6,
    header: "Other Settings",
    name: "Utilities",
    url: "#", // Placeholder if the main item doesn't have a direct link
    icon: <IcUtilities />,
    permission_name: "view_settings",
    submenu: [
      {
        id: 3,
        name: "File Manager",
        url: "/admin/utilities/file-manager",
        permission_name: "view_general_settings",
      },
      {
        id: 4,
        name: "User Settings",
        url: "/admin/settings/users",
        permission_name: "view_user_settings",
      },
    ],
  },
  {
    id: 7,
    header: "Content Management",
    name: "Blog",
    url: "#", // Placeholder if the main item doesn't have a direct link
    icon: <IcUtilities />,
    permission_name: "view_settings",
    submenu: [
      {
        id: 3,
        name: "Add Blog",
        url: "/admin/content/add-blog",
        permission_name: "view_general_settings",
      },
      {
        id: 4,
        name: "View Blog",
        url: "/admin/content/blog",
        permission_name: "view_user_settings",
      },
    ],
  },
];

export default sidebarMenu;
