import React, { useEffect, useState } from "react";

const FinalServices = ({ handleOrderSubmit }) => {
  const [data, setData] = useState(() => {
    const savedData = sessionStorage.getItem("selectedServices");
    return savedData ? JSON.parse(savedData) : [];
  });
  // Function to fetch and update data from sessionStorage
  const updateData = () => {
    const storedData = sessionStorage.getItem("selectedServices");
    if (storedData) {
      try {
        setData(JSON.parse(storedData));
      } catch (error) {
        console.error("Failed to parse sessionStorage data:", error);
      }
    } else {
      setData([]); // Handle case when there is no data
    }
  };

  useEffect(() => {
    // Initial fetch of sessionStorage data
    updateData();

    // Set up an event listener for `storage` events from other tabs or windows
    const handleStorageEvent = (event) => {
      if (event.key === "selectedServices") {
        updateData(); // Update data if "selectedServices" is modified
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);

  useEffect(() => {
    const handleSessionStorageChange = (event) => {
      if (event.key === "selectedServices") {
        updateData();
      }
    };

    // Override sessionStorage.setItem to detect changes made programmatically in the same tab
    const originalSetItem = sessionStorage.setItem;
    sessionStorage.setItem = function (key, value) {
      const event = new Event("storage"); // Manually create a storage event
      event.key = key;
      event.newValue = value;
      document.dispatchEvent(event);
      originalSetItem.apply(this, arguments); // Call the original setItem method
    };

    // Listen for manual storage changes
    document.addEventListener("storage", handleSessionStorageChange);

    return () => {
      document.removeEventListener("storage", handleSessionStorageChange);
      sessionStorage.setItem = originalSetItem; // Restore original setItem
    };
  }, []);
  const calculateSubTotal = () => {
    return data.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0); // Start with total = 0
  };

  const subTotal = calculateSubTotal();
  const handleOrder = () => {
    sessionStorage.removeItem("selectedServices");
    window.location.href = "/";
  };
  return (
    <div className="px-6 py-3">
      <div className="w-full py-2 px-4 text-center bg-white rounded-lg shadow-md border">
        <p>Selected Services</p>
      </div>
      <div className="bg-white rounded-lg shadow-md mt-3 border">
        {data &&
          data.map((item, index) => (
            <div key={index} className="w-full py-2 px-4 border-b">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-md font-medium">{item.service_name}</p>
                  <span className="text-xs">
                    Quantity: {item.quantity || 0}
                  </span>
                </div>
                <div>
                  <p className="text-md font-medium">
                    ৳{item.price * item.quantity || 0}.00
                  </p>
                </div>
              </div>
            </div>
          ))}

        <div className="w-full py-2 px-4 flex justify-between items-center border-t">
          <p className="font-bold">Subtotal: </p>
          <p className="font-semibold">৳{subTotal || 0}.00</p>
        </div>
      </div>
      <button
        onClick={handleOrderSubmit}
        className="bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] text-white text-center font-semibold px-4 py-2 w-full rounded-lg mt-3"
      >
        Process Checkout
      </button>
    </div>
  );
};

export default FinalServices;
