import { Container } from "@mui/material";
import React from "react";
import { TiTick } from "react-icons/ti";

const SingleSubCategoryHero = ({ Data }) => {
  return (
    <div
      className="relative w-full h-[350px] bg-cover bg-no-repeat bg-center text-white flex justify-center items-center"
      style={{ backgroundImage: `url(${Data.subCatBannerUrl})` }}
    >
      <div className="absolute inset-0 bg-gray-900 opacity-70"></div>

      <Container>
        <div className="relative z-10">
          <p className="text-[2rem] md:text-[2.5rem] lg:text-[2.5rem] xl:text-[2.5rem] 2xl:text-[2.5rem] font-semibold capitalize text-start">
            {Data.sub_category_name}
          </p>
          {Data.highlight_one !== null && (
            <div className="flex justify-start items-center gap-2">
              <div className="w-5 h-5 rounded-full bg-white text-cyan-700 flex justify-center items-center">
                <TiTick />
              </div>
              <div>
                <p className="text-lg">{Data.highlight_one}</p>
              </div>
            </div>
          )}
          {Data.highlight_two !== null && (
            <div className="flex justify-start items-center gap-2 mt-2">
              <div className="w-5 h-5 rounded-full bg-white text-cyan-700 flex justify-center items-center">
                <TiTick />
              </div>
              <div>
                <p className="text-lg">{Data.highlight_two}</p>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SingleSubCategoryHero;
