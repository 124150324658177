import React, { useEffect, useState } from "react";
import Container from "../../../../utils/Container";
import AxiosInstance from "../../../../axiosInstance";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const BlogSec = () => {
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      AxiosInstance.get(`/blog/blogs`)
        .then((res) => {
          setData(res.data);
          setLoading(false); // Stop loading after setting data
        })
        .catch((err) => {
          console.log(err);
          setLoading(false); // Stop loading even if there is an error
        });
    }, 1000);
  }, []);
  const sanitizeProductName = (name) => {
    return name
      .toLowerCase() // Convert to lowercase
      .replace(/\?+$/g, ""); // Remove the last ? if it exists
  };

  return (
    <div className="mb-5">
      <Container>
        {Data.length > 0 ? (
          <>
            <div className="mt-5 md:mt-10 lg:mt-32 xl:mt-32 2xl:mt-20 mb-16 md:mb-20 lg:mb-20 xl:mb-20 2xl:mb-10">
              <p className="text-center text-lg md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold text-black/80 mb-5">
                Blog Section | <small>Our latest blogs</small>
              </p>
              <div className="flex justify-between items-center gap-5">
                <Swiper
                  slidesPerView={2}
                  spaceBetween={10} // Adjust space between slides
                  navigation={true}
                  pagination={{ type: "fraction" }}
                  modules={[Navigation]}
                  className="w-full" // Ensure Swiper takes full width
                  breakpoints={{
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {Data &&
                    Data.map((item, index) => (
                      <SwiperSlide
                        key={index}
                        className="flex flex-col items-center"
                      >
                        <Link
                          to={`/blog/${sanitizeProductName(item.blog_title)}/${
                            item.id
                          }`}
                        >
                          <div className="w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px] overflow-hidden rounded-lg shadow-md">
                            <img
                              className="w-full h-full object-cover"
                              src={item.blogImageUrl}
                              alt={item.blog_title}
                            />
                          </div>
                          <h1 className="text-md font-semibold mt-3 text-center">
                            {item.blog_title}
                          </h1>
                        </Link>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-flow-col gap-2 grid-cols-3 mt-10">
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default BlogSec;
