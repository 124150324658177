import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWebsite } from "../../../../../context/admin/websiteSlice/websiteSlice";
import Submit from "../submit/Submit";
import facebooklogo from "../../../../../assets/frontend/icon/facebook.png";
import instagramlogo from "../../../../../assets/frontend/icon/instagram.png";
import linkedinlogo from "../../../../../assets/frontend/icon/linkedin.png";
import youtube from "../../../../../assets/frontend/icon/youtube.png";
const SocialMedia = ({ handleViewData }) => {
  const website = useSelector((state) => state.website);

  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch(
      setWebsite({
        ...setWebsite,
        [id]: value,
      })
    );
  };
  return (
    <div className="mt-5 border px-6 py-4 rounded-lg">
      <p className="text-lg font-medium leading-5">Social Media</p>
      <small>Paste your social media links on respective fields.</small>
      <div className="flex flex-col mt-2 gap-y-7">
        <div className="flex items-center justify-center gap-2 border px-2 rounded-md">
          <img className="w-[25px]" src={facebooklogo} alt="" />
          <input
            onChange={handleChange}
            value={website.facebook}
            id="facebook"
            type="text"
            placeholder="Facebook URL"
            className=" w-full px-2 py-1 focus:outline-none"
          />
        </div>
        <div className="flex items-center justify-center gap-2 border px-2 rounded-md">
          <img className="w-[25px]" src={instagramlogo} alt="" />
          <input
            onChange={handleChange}
            value={website.instagram}
            id="instagram"
            type="text"
            placeholder="Instagram URL"
            className=" w-full px-2 py-1 focus:outline-none"
          />
        </div>

        <div className="flex items-center justify-center gap-2 border px-2 rounded-md">
          <img className="w-[25px]" src={linkedinlogo} alt="" />
          <input
            onChange={handleChange}
            value={website.linkedin}
            id="linkedin"
            type="text"
            placeholder="Linkedin URL"
            className=" w-full px-2 py-1 focus:outline-none"
          />
        </div>
        <div className="flex items-center justify-center gap-2 border px-2 rounded-md">
          <img className="w-[25px]" src={youtube} alt="" />
          <input
            onChange={handleChange}
            value={website.youtube}
            id="youtube"
            type="text"
            placeholder="Youtube URL"
            className=" w-full px-2 py-1 focus:outline-none"
          />
        </div>
      </div>
      <Submit handleViewData={handleViewData} />
    </div>
  );
};

export default SocialMedia;
