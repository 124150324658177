import AdminLayout from "../../layout/admin/AdminLayout";
import Banner from "../../view/admin/banner/Banner";
import Blog from "../../view/admin/blog/Blog";
import AddBlog from "../../view/admin/blog/components/add-blog/AddBlog";
import EditBlog from "../../view/admin/blog/components/edit-blog/EditBlog";
import Category from "../../view/admin/cateogry/Category";
import Dashboard from "../../view/admin/dashboard/Dashboard";
import FileManager from "../../view/admin/file-manager/FileManager";
import AddDisplay from "../../view/admin/front-end-control/display/add-display/AddDisplay";
import DisplayServices from "../../view/admin/front-end-control/display/DisplayServices";
import OrderDetails from "../../view/admin/order/components/order-details/OrderDetails";
import ServiceRequest from "../../view/admin/service-request/ServiceRequest";
import AddService from "../../view/admin/services/components/add-service/AddService";
import EditService from "../../view/admin/services/components/edit-service/EditService";
import Services from "../../view/admin/services/Services";
import SMS from "../../view/admin/sms/SMS";
import AddSubCategory from "../../view/admin/subcategory/components/add-sub-category/AddSubCategory";
import EditSubCategory from "../../view/admin/subcategory/components/edit-sub-category/EditSubCategory";
import SubCategory from "../../view/admin/subcategory/SubCategory";
import Website from "../../view/admin/website/Website";
import Order from "./../../view/admin/order/Order";

const AdminRoutes = {
  path: "/admin",
  element: <AdminLayout />,
  children: [
    {
      path: "/admin/order-management/order",
      element: <Order />,
    },
    {
      path: "accounts",
      element: <Dashboard />,
    },
    {
      path: "/admin/settings/sms",
      element: <SMS />,
    },
    {
      path: "/admin/settings/website",
      element: <Website />,
    },
    {
      path: "/admin/utilities/file-manager",
      element: <FileManager />,
    },
    {
      path: "/admin/category/category",
      element: <Category />,
    },
    {
      path: "/admin/subcategory/subcategory",
      element: <SubCategory />,
    },
    {
      path: "/admin/subcategory/add-subcategory",
      element: <AddSubCategory />,
    },
    {
      path: "/admin/subcategory/edit-subcategory/:id",
      element: <EditSubCategory />,
    },
    {
      path: "/admin/services/services",
      element: <Services />,
    },
    {
      path: "/admin/services/add-service",
      element: <AddService />,
    },
    {
      path: "/admin/services/edit-service/:id",
      element: <EditService />,
    },
    {
      path: "/admin/frontend/display/services",
      element: <DisplayServices />,
    },
    {
      path: "/admin/frontend/display/services/add-services",
      element: <AddDisplay />,
    },
    {
      path: "/admin/frontend/banners",
      element: <Banner />,
    },
    {
      path: "/admin/order-management/order",
      element: <Order />,
    },
    {
      path: "/admin/order-management/order-details/:id",
      element: <OrderDetails />,
    },
    {
      path: "/admin/order-management/service-request",
      element: <ServiceRequest />,
    },
    {
      path: "/admin/content/blog",
      element: <Blog />,
    },
    {
      path: "/admin/content/add-blog",
      element: <AddBlog />,
    },
    {
      path: "/admin/content/edit-blog/:id",
      element: <EditBlog />,
    },
  ],
};

export default AdminRoutes;
