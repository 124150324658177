import React from "react";
import { FaEdit } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { useNavigate } from "react-router";
import AxiosInstance from "../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";

const TableContent = ({
  filteredData,
  tableHead,
  tableData,
  editLink,
  deleteApi,
  deletedText,
  handleViewData,
}) => {
  const navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`${editLink}${id}`);
  };
  const handleDelete = (id) => {
    AxiosInstance.delete(`${deleteApi}${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success(deletedText, {
            position: "bottom-center",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          handleViewData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <table className="w-full mt-5">
        <thead>
          <tr className="text-center text-sm">
            {tableHead.map((header, index) => (
              <th key={index} className="border px-4 py-2">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData &&
            filteredData.map((item, index) => (
              <tr key={index} className="text-center text-sm">
                {tableData.map((td, i) => (
                  <td
                    key={i}
                    className="border px-4 py-2"
                    dangerouslySetInnerHTML={{ __html: td(item, index) }}
                  />
                ))}
                <td className="border px-4 py-2 ">
                  <div className="flex justify-center items-center gap-3">
                    <button onClick={() => handleEdit(item.id)}>
                      <FaEdit className="text-lg text-green-700" />
                    </button>
                    <button>
                      <TiDelete
                        onClick={() => handleDelete(item.id)}
                        className="text-2xl text-red-500"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableContent;
