import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import FileManagerPopup from "../../../../../components/admin/FileManager/FileManagerPopup";
import { useSelector } from "react-redux";
import { addBlog, getImagebyId } from "../../../../../api/backend/backendAPI";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router";

const AddBlog = () => {
  const navigate = useNavigate();
  const selectedImage = useSelector(
    (state) => state.imageSelected.blogImageSelected
  );
  const editorRef = useRef(null);
  const [EditorContent, setEditorContent] = useState("");
  const [OpenFileManager, setOpenFileManager] = useState(false);
  const [SelectedImage, setSelectedImage] = useState("");
  const [FormData, setFormData] = useState({
    blog_title: "",
    blog_image_id: "",
    blog_body: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      blog_body: EditorContent,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      blog_image_id: selectedImage,
    }));
  }, [EditorContent, selectedImage]);
  const handleCloseFileManager = () => {
    setOpenFileManager(false);
  };
  useEffect(() => {
    getImagebyId(selectedImage, setSelectedImage);
  }, [selectedImage]);
  const handleSubmit = () => {
    if (
      FormData.blog_title !== "" ||
      FormData.blog_body !== "" ||
      FormData.blog_image_id !== ""
    ) {
      addBlog(FormData, navigate);
    } else {
      toast.error("Please fill up all the fields.", {
        position: "bottom-center",
        autoClose: 1200,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };
  return (
    <>
      {OpenFileManager && (
        <FileManagerPopup
          blogImageNeeded={true}
          handleCloseFileManager={handleCloseFileManager}
        />
      )}
      <div className="w-1/2 border bg-white rounded-lg px-4 py-2 shadow-md">
        <p className="font-semibold text-lg text-center mb-5">Add Blog</p>
        <div className="my-3">
          <label htmlFor="">
            <p>Blog Title</p>
          </label>
          <input
            type="text"
            className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
            placeholder="Title"
            onChange={handleChange}
            name="blog_title"
            value={FormData.blog_title}
          />
        </div>
        <div className="my-3">
          <label htmlFor="">
            <p>Select Image</p>
          </label>
          <input
            type="text"
            className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
            placeholder="Content"
            onClick={() => setOpenFileManager(true)}
          />
          {SelectedImage && (
            <div className="w-[200px] mt-3 h-auto px-47 py-2 rounded-lg border">
              <img
                className="w-full h-full object-cover px-4 py-2"
                src={SelectedImage.fileUrl}
                alt=""
              />
            </div>
          )}
        </div>
        <div className="my-3">
          <label htmlFor="">
            <p>Content</p>
          </label>
          <JoditEditor
            ref={editorRef}
            value={EditorContent}
            onChange={(e) => setEditorContent(e)}
          />
        </div>
        <div className="my-5 flex justify-end">
          <button
            onClick={handleSubmit}
            className="text-white text-sm bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] px-4 py-2 rounded-2xl"
          >
            Add Blog
          </button>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
