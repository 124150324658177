import React, { useEffect, useState } from "react";
import AddDisplay from "./add-display/AddDisplay";
import EditDisplay from "./edit-display/EditDisplay";
import AxiosInstance from "../../../../axiosInstance";

const DisplayServices = () => {
  const [displayData, setDisplayData] = useState([]);
  const [formData, setFormData] = useState([]);
  const handleViewData = () => {
    AxiosInstance.get(`/display/service-displays`)
      .then((res) => {
        setDisplayData(res.data);
        setFormData(res.data); // Initialize form data with existing display data
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Fetch existing display services on component mount
  useEffect(() => {
    handleViewData();
  }, []);
  return (
    <div className="">
      <AddDisplay handleViewData={handleViewData} />
      <EditDisplay
        setDisplayData={setDisplayData}
        formData={formData}
        setFormData={setFormData}
        handleViewData={handleViewData}
      />
    </div>
  );
};

export default DisplayServices;
