import React from "react";
import Logo from "../../../assets/frontend/logo/logo.png";
const Loader = () => {
  return (
    <div className="bg-white w-screen h-screen flex justify-center items-center">
      <div className="animate-bounce">
        <img className="w-[150px]" src={Logo} alt="" />
      </div>
    </div>
  );
};

export default Loader;
