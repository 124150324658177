import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryIcon: "",
  categoryBanner: "",
  subCategoryIcon: "",
  subCategoryBanner: "",
  serviceImage: "",
  heroBanner: "",
  footerBanner: "",
  imageSelected: "",
  blogImageSelected: "",
  multipleImageSelected: [],
};
const imageSelectedSlice = createSlice({
  name: "imageSelected",
  initialState,
  reducers: {
    setCategoryIcon: (state, action) => {
      state.categoryIcon = action.payload;
    },
    setCategoryBanner: (state, action) => {
      state.categoryBanner = action.payload;
    },
    setSubCategoryIcon: (state, action) => {
      state.subCategoryIcon = action.payload;
    },
    setSubCategoryBanner: (state, action) => {
      state.subCategoryBanner = action.payload;
    },
    setServiceImage: (state, action) => {
      state.serviceImage = action.payload;
    },
    setHeroBanner: (state, action) => {
      state.heroBanner = action.payload;
    },
    setFooterBanner: (state, action) => {
      state.footerBanner = action.payload;
    },
    setImageSelected: (state, action) => {
      state.imageSelected = action.payload;
    },
    setMultipleImageSelected: (state, action) => {
      state.multipleImageSelected = action.payload;
    },
    setBlogImageSelected: (state, action) => {
      state.blogImageSelected = action.payload;
    },
  },
});
export const {
  setImageSelected,
  setMultipleImageSelected,
  setCategoryIcon,
  setCategoryBanner,
  setSubCategoryIcon,
  setSubCategoryBanner,
  setServiceImage,
  setHeroBanner,
  setFooterBanner,
  setBlogImageSelected,
} = imageSelectedSlice.actions;
export default imageSelectedSlice.reducer;
