import React, { useEffect, useState } from "react";
import CategoryTable from "./components/category-table/CategoryTable";
import AxiosInstance from "../../../axiosInstance";

const Category = () => {
  const [CategoryData, setCategoryData] = useState([]);
  const handleViewData = () => {
    AxiosInstance.get("/category/category")
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleViewData();
  }, []);
  return (
    <div>
      <CategoryTable
        handleViewData={handleViewData}
        CategoryData={CategoryData}
      />
    </div>
  );
};

export default Category;
