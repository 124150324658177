import React, { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import FileManagerPopup from "../../../../../components/admin/FileManager/FileManagerPopup";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import {
  setSubCategoryBanner,
  setSubCategoryIcon,
} from "../../../../../context/admin/ImageSelectedSlice/ImageSelectedSlice";

const EditSubCategory = () => {
  const subCategoryId = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SubCatIcon = useSelector(
    (state) => state.imageSelected.subCategoryIcon
  );
  const SubCatBanner = useSelector(
    (state) => state.imageSelected.subCategoryBanner
  );
  const [SubCatIconUrl, setSubCatIconUrl] = useState("");
  const [SubCatBannerUrl, setSubCatBannerUrl] = useState("");
  const editorRef = useRef();
  const [EditorContent, setEditorContent] = useState("");
  const [ShowFileManagerForIcon, setShowFileManagerForIcon] = useState(false);
  const [ShowManagerforBanner, setShowManagerforBanner] = useState(false);
  const [CategoryData, setCategoryData] = useState([]);
  const [FormData, setFormData] = useState({
    sub_category_name: "",
    sub_cat_icon: "",
    sub_cat_banner: "",
    highlight_one: "",
    highlight_two: "",
    details: "",
    main_category_id: "",
    status: 1,
    created_by: "user",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      details: EditorContent,
    }));
  }, [EditorContent]);
  const handleShowFileManagerForIcon = () => {
    setShowFileManagerForIcon(true);
  };
  const handleCloseFileManagerForIcon = () => {
    setShowFileManagerForIcon(false);
  };
  const handleShowFileManagerForBanner = () => {
    setShowManagerforBanner(true);
  };
  const handleCloseFileManagerForBanner = () => {
    setShowManagerforBanner(false);
  };
  useEffect(() => {
    AxiosInstance.get(`/file/get-file/${SubCatIcon}`)
      .then((res) => {
        setSubCatIconUrl(res.data.fileUrl);
      })
      .catch((err) => {
        console.log(err);
      });
    AxiosInstance.get(`/file/get-file/${SubCatBanner}`)
      .then((res) => {
        setSubCatBannerUrl(res.data.fileUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [SubCatIcon, SubCatBanner]);
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sub_cat_icon: SubCatIcon,
      sub_cat_banner: SubCatBanner,
    }));
  }, [SubCatIcon, SubCatBanner]);
  const handleSubmit = () => {
    if (
      FormData.sub_cat_icon === "" ||
      FormData.sub_cat_banner === "" ||
      EditorContent === "" ||
      FormData.main_category_id === "" ||
      FormData.sub_category_name === ""
    ) {
      toast.error("Please fill up all the fields.", {
        position: "bottom-center",
        autoClose: 1200,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      AxiosInstance.put(
        `/subcategory/update-sub-category/${subCategoryId.id}`,
        FormData
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Sub category has been edited.", {
              position: "bottom-center",
              autoClose: 1200,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            navigate("/admin/subcategory/subcategory");
            setFormData({
              sub_category_name: "",
              sub_cat_icon: "",
              sub_cat_banner: "",
              highlight_one: "",
              highlight_two: "",
              details: "",
              main_category_id: "",
              status: 1,
              created_by: "user",
            });
            setEditorContent("");
            dispatch(setSubCategoryBanner(""));
            dispatch(setSubCategoryIcon(""));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    AxiosInstance.get("/category/category")
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleSelectMainCategory = (id) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      main_category_id: id, // This will store the selected category id
    }));
  };
  useEffect(() => {
    AxiosInstance.get(`/subcategory/sub-category/${subCategoryId.id}`).then(
      (res) => {
        setFormData(res.data);
        setEditorContent(res.data.details);
        setSubCatIconUrl(res.data.sub_cat_icon);
        setSubCatBannerUrl(res.data.sub_cat_banner);

        setFormData((prevFormData) => ({
          ...prevFormData,
          main_category_id: res.data.main_category_id,
          sub_category_name: res.data.sub_category_name,
          sub_cat_icon: res.data.sub_cat_icon,
          sub_cat_banner: res.data.sub_cat_banner,
          highlight_one: res.data.highlight_one,
          highlight_two: res.data.highlight_two,
          details: res.data.details,
        }));
        dispatch(setSubCategoryBanner(res.data.sub_cat_banner));
        dispatch(setSubCategoryIcon(res.data.sub_cat_icon));
      }
    );
  }, [subCategoryId.id]);
  console.log(subCategoryId.id);
  return (
    <>
      {ShowManagerforBanner && (
        <FileManagerPopup
          subCategoryIconNeeded={false}
          subCategoryBannerNeeded={true}
          handleCloseFileManagerForBanner={handleCloseFileManagerForBanner}
        />
      )}
      {ShowFileManagerForIcon && (
        <FileManagerPopup
          subCategoryIconNeeded={true}
          subCategoryBannerNeeded={false}
          handleCloseFileManagerForIcon={handleCloseFileManagerForIcon}
        />
      )}

      <div className="p-4 flex justify-start items-center flex-col ">
        <div className="w-1/2 border px-4 py-4 rounded-xl shadow-lg">
          <h1 className="text-xl font-bold text-center">Edit Sub Category</h1>
          <div className=" ">
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Sub Category Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="sub_category_name"
                onChange={handleChange}
                value={FormData.sub_category_name}
                placeholder="Enter Sub Category Name"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
              />
            </div>

            <div className="my-7">
              <label htmlFor="sub_cat_icon" className="text-sm font-medium">
                Sub Category Icon <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                onChange={handleChange}
                onClick={handleShowFileManagerForIcon}
                value={FormData.sub_cat_icon}
                autoComplete="off"
                name="sub_cat_icon"
                placeholder="Enter Sub Category Icon"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
              />
              {SubCatIconUrl && (
                <div className="w-[60px] h-[60px] mt-3 border px-1 py-1 rounded-lg">
                  <img
                    className="w-full h-full object-contain"
                    src={SubCatIconUrl}
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="my-7">
              <label htmlFor="sub_cat_banner" className="text-sm font-medium">
                Sub Category Banner <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                onChange={handleChange}
                value={FormData.sub_cat_banner}
                autoComplete="off"
                onClick={handleShowFileManagerForBanner}
                name="sub_cat_banner"
                placeholder="Enter Sub Category Banner"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              />
              {SubCatBannerUrl && (
                <div className="w-[180px] h-[100px] mt-3 border px-1 py-1 rounded-lg">
                  <img
                    className="w-full h-full object-contain"
                    src={SubCatBannerUrl}
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="my-7">
              <label htmlFor="highlight_one" className="text-sm font-medium">
                Highlighed TagLine
              </label>
              <input
                type="text"
                onChange={handleChange}
                value={FormData.highlight_one}
                name="highlight_one"
                placeholder="Highlighed TagLine"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              />
            </div>

            <div className="my-7">
              <label htmlFor="highlight_two" className="text-sm font-medium">
                Highlighed TagLine Two
              </label>
              <input
                type="text"
                onChange={handleChange}
                value={FormData.highlight_two}
                name="highlight_two"
                placeholder="Highlighed TagLine Two"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              />
            </div>
            <div className="my-7">
              <label htmlFor="highlight_two" className="text-sm font-medium">
                Choose Main Category <span className="text-red-500">*</span>
              </label>
              <select
                onChange={(e) => handleSelectMainCategory(e.target.value)}
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
              >
                {CategoryData &&
                  CategoryData.length > 0 &&
                  CategoryData.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.category_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="my-7">
              <label htmlFor="details" className="text-sm font-medium">
                Sub Category Details <span className="text-red-500">*</span>
              </label>
              <JoditEditor
                ref={editorRef}
                value={EditorContent}
                onChange={(e) => setEditorContent(e)}
              />
            </div>
          </div>
          <div className="flex justify-end items-center gap-3 mt-[3.5rem]">
            <button className="text-black/80 text-sm  px-4 py-2 rounded-2xl">
              clear
            </button>
            <button
              onClick={handleSubmit}
              className="text-white text-sm bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] px-4 py-2 rounded-2xl"
            >
              Add Sub Category
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSubCategory;
