import TablePagination from "@mui/material/TablePagination";
import React, { useState } from "react";

const TablePage = ({
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  count,
}) => {
  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 100, 500]}
      component="div"
      rowsPerPage={rowsPerPage}
      count={count}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default TablePage;
