import React from "react";
import { Link } from "react-router-dom";

const SearchResult = ({
  searchInput,
  setSearchInput,
  filteredResults,
  handleLinkClick,
}) => {
  return (
    <>
      {searchInput && (
        <div className="absolute top-[40%] xl:top-[55%] xl:right-[30%] 2xl:top-[38%] right-0 2xl:right-[36%] mt-2 w-full xl:w-[500px]  2xl:w-[500px] bg-white h-auto border z-30 rounded-lg">
          {filteredResults.length > 0 ? (
            filteredResults.map((category) => (
              <div key={category.id} className="p-2 border-b">
                {/* Display Category Name */}
                <div className="flex justify-start items-center gap-2">
                  <div className="w-10 h-10 border rounded-lg">
                    <img src={category.categoryBannerUrl} alt="" />
                  </div>
                  <div className="font-semibold text-lg">
                    {category.category_name}
                  </div>
                </div>

                {/* Display Matching Subcategories */}
                {category.sub_categories.length > 0 &&
                  category.sub_categories.map((subCategory) => {
                    if (
                      subCategory.sub_category_name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    ) {
                      return (
                        <div key={subCategory.sub_category_id}>
                          <Link
                            to={`/${subCategory.sub_category_name}`}
                            onClick={handleLinkClick} // Hide the search results on click
                          >
                            <div className="pl-4 mt-2 flex justify-start items-center gap-2">
                              <div className="w-8 h-8 border rounded-lg">
                                <img
                                  src={subCategory.subCatBannerUrl}
                                  alt={subCategory.sub_category_name}
                                />
                              </div>
                              <div className="text-gray-500">
                                {subCategory.sub_category_name}
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            ))
          ) : (
            <div className="p-4 text-center text-gray-500">
              No matching results found
            </div>
          )}
        </div>
      )}{" "}
    </>
  );
};

export default SearchResult;
