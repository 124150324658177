import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import Logo from "../../../../assets/frontend/logo/sheba.svg";
import AxiosInstance from "../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setPhoneNumberForOTP } from "../../../../context/frontend/otpSlice/otpSlice";
import {
  setAcessToken,
  setUserInfo,
} from "../../../../context/frontend/loginSlice/loginSlice";

const OtpVerification = ({ handleCancel }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const phone_number = useSelector((state) => state.phoneNumber.phone_number);

  const handleClose = () => {
    handleCancel();
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === "") {
      let newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if available and value is not empty
      if (value !== "" && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const isButtonDisabled = otp.some((digit) => digit === "");

  const handleVerifyOtp = () => {
    AxiosInstance.post("/auth/verify-otp", {
      phone_number: phone_number,
      otp: otp.join(""),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("OTP verified successfully", {
            position: "top-right",
            autoClose: 1300,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          dispatch(setPhoneNumberForOTP(""));
          sessionStorage.setItem("accessToken", res.data.token);
          sessionStorage.setItem("userInfo", JSON.stringify(res.data.user));
          setOtp(["", "", "", ""]);
          handleCancel();
          dispatch(setAcessToken(res.data.token));
          dispatch(setUserInfo(res.data.user));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="absolute top-0 left-0 w-screen h-screen bg-black/20 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out opacity-100">
      <div className="bg-white relative p-6 rounded-md shadow-md w-[550px] h-[450px] flex justify-center items-center transform transition-all duration-300 ease-out scale-95">
        <button
          onClick={handleClose}
          className="absolute -top-4 text-3xl -right-3 bg-white rounded-full text-rose-600 cursor-pointer"
        >
          <MdCancel />
        </button>
        <div>
          <div className="flex justify-center items-center flex-col">
            <img className="w-[150px]" src={Logo} alt="Logo" />
            <p className="mt-3 text-md text-black/70 font-medium">
              Verify your OTP
            </p>
          </div>
          <div className="mt-3 flex justify-center items-center gap-7">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                value={digit}
                maxLength={1}
                id={`otp-input-${index}`}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className="w-20 h-20 border-2 focus:outline-none rounded-lg text-center text-3xl font-semibold"
                autoFocus={index === 0}
              />
            ))}
          </div>

          <div className="mt-3">
            <button
              onClick={handleVerifyOtp}
              className={`w-full py-2 text-white rounded-lg font-semibold ${
                isButtonDisabled
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700"
              }`}
              disabled={isButtonDisabled}
            >
              Verify OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
